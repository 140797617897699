import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { useCallback } from "react";
import {
  RequestWishList
} from "../store/wishlist/wishlistSlice";
import { RequestPostToCart } from "../store/cart/cartSlice";
import {
  RequestPostToGuestCart,
} from "../store/guestCart/guestCart";
import { setOpenDrawerCart } from "../store/globalSlice";
import { toast } from "react-toastify";

function useAddToCart() {
  const dispatch = useDispatch<AppDispatch>();

  // token
  const user = useSelector((state: RootState) => state.user.user);
  const guestUser = useSelector((state: RootState) => state.guestCart.cartID);


  

//   quantity: any, product_id: any
  const handleAddItem = (quantity: any, product_id: any) => {
    // setOpenDrawer(true);
    // dispatch(setOpenDrawerCart(true))
    if (user) {
      dispatch(RequestPostToCart({ quantity: quantity, product_id: product_id }))
    } 
    else {
      dispatch(
        RequestPostToGuestCart({ quantity: quantity, product_id: product_id })
      );
    } 
    // if (guestUser !== null)
    // else {
    //   dispatch(()).then((result) => { 
    //     dispatch(
    //       RequestPostToGuestCart({ quantity: quantity, product_id: product_id })
    //     );
    //   });
    // }
  };

  return handleAddItem;
}

export default useAddToCart;
