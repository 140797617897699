import { Box, Container, Grid, Typography } from "@mui/material";
import React, { useEffect } from "react";
import HeroHeading from "../../components/common/HeroHeading";
import PhoneField from "../../components/common/phoneField";
import { MainButton, ProfileList } from "../../style/style";
import LastNav from "../../components/layout/lastNav";
import Footer from "../../components/layout/footer";
import ControlField from "../../components/common/controlField";
import { MdOutlineArrowForwardIos } from "react-icons/md";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ProfileLayout from "../../components/profile/profileLayout";
import BreadCrumbs from "../../components/common/breadCrumbs";
import { t } from "i18next";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestEditProfilename,
  RequestEditProfilephone,
  RequestGetProfile,
  RequestUpdateCustomer,
  setValueUser,
} from "../../store/profile/profileSlice";
import { useFormik } from "formik";
import { phoneWithoutZero, setErrorMsg, setUserDetails } from "../../store/user/user";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import AlertResponse from "../../components/common/alertResponse";

const AccountDetailsEdit = () => {
  const crumbs = [
    { label: `${t("Home")}`, link: "/", active: false },
    { label: `${t("profile")}`, link: "/profile", active: false },
    { label: `${t("editprofile")}`, link: "/profile/edit", active: true },
  ];
  const dispatch = useDispatch<AppDispatch>();
  const { profile, error, customer } = useSelector(
    (state: RootState) => state.profile
  );

  const {userDetails} = useSelector((state:RootState) => state.user)

  const handleSubmit = (e: any) => {
    e.preventDefault();
    if (profile.phone.slice(3) === customer.phone) {
      dispatch(
        RequestUpdateCustomer({ first_name: customer.first_name, phone: null })
      ).then((result) => {
        if (result.type === "RequestUpdateCustomer/fulfilled") {
          const local: any = localStorage.getItem("userDetails");
          const localObj: any = JSON.parse(local);
          let localStr = { ...localObj, first_name: customer.first_name };
          localStorage.setItem("userDetails", JSON.stringify(localStr));
          dispatch(setUserDetails({...userDetails, first_name: customer.first_name}))
        }
      });
    } else {
      dispatch(
        RequestUpdateCustomer({
          first_name: customer.first_name,
          phone: customer.phone,
        })
      ).then((result) => {
        if (result.type === "RequestUpdateCustomer/fulfilled") {
          dispatch(setOpenLogin(true));
          dispatch(setSignType("reset"));

          const local: any = localStorage.getItem("userDetails");
          const localObj: any = JSON.parse(local);
          let localStr = {
            ...localObj,
            first_name: customer.first_name,
            phone: customer.phone,
          };
          localStorage.setItem("userDetails", JSON.stringify(localStr));

          dispatch(setUserDetails({...userDetails, first_name: customer.first_name, phone:`+20${customer.phone}`}))

        }
      });
    }
  };
  useEffect(() => {
    dispatch(RequestGetProfile());
  }, [dispatch]);

  return (
    <ProfileLayout crumbs={crumbs}>
      <form onSubmit={handleSubmit}>
        <Box>
          <HeroHeading heading={t("editprofile")} />
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "20px",
              mt: "24px",
              width: "100%",
            }}
          >
            <Box>
              <Typography
                sx={{ fontSize: "16px", fontWeight: "500", mb: "8px" }}
              >
                {t("fullname")}
              </Typography>
              <ControlField
                type="text"
                id="first_name"
                sx={{ width: { md: "25%", sm: "50%", xs: "100%" } }}
                name="first_name"
                value={customer?.first_name}
                onChange={(e: any) => {
                  dispatch(
                    setValueUser({ name: "first_name", value: e.target.value })
                  );
                }}
              />
            </Box>
            <Box>
              <PhoneField
                label={t("mobile_number")}
                id="phone"
                name="phone"
                sx={{
                  width: {
                    md: "25% !important",
                    sm: "50% !important",
                    xs: "100%",
                  },
                }}
                value={customer?.phone}
                onChange={(e: any) => {
                  dispatch(setValueUser({ name: "phone", value: e }));

                }}
              />
            </Box>
          </Box>
          <MainButton
            sx={{ mt: "40px", padding: { md: "12px 141px" } }}
            type="submit"
          >
            {t("change_password")}
          </MainButton>
        </Box>
      </form>
      {/* <AlertResponse type={"error"} message={error} /> */}
    </ProfileLayout>
  );
};

export default AccountDetailsEdit;
