import React, { LegacyRef, useEffect, useRef, useState } from "react";
import {
  Box,
  Container,
  Grid,
  Typography,
  styled,
  Stack,
  css,
} from "@mui/material";
import Footer from "../components/layout/footer";
import StarIcon from "@mui/icons-material/Star";
import { colors } from "../utils/theme";
import { IconBox, MainButton } from "../style/style";
import BasicTable from "../components/productDetails/table";
import RecommendSection from "../components/home/LikeYouSection";
import ReviewSection from "../components/productDetails/reviewSection";
import { BiSolidDownArrow } from "react-icons/bi";
import { BiSolidUpArrow } from "react-icons/bi";
// import ReactImageMagnify from "react-image-magnify";
import OfferDetails from "../components/productDetails/offerDetails";
import { savedIcon, transferIcon, uploadIcon } from "../assets/svg/svg";
import BreadCrumbs from "../components/common/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import { RequestGetProduct } from "../store/product/productSlice";
import ProductsInfo from "../components/productDetails/productsInfo";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Skeleton from "@mui/material/Skeleton";
import { t } from "i18next";
import "../assets/Vector (5).png";
import { number } from "prop-types";
import AlertResponse from "../components/common/alertResponse";
import i18n from "../i18n";
import useCompare from "../hooks/useCompare";
import { setBread } from "../store/products/productsSlice";
import TestImage from "../components/common/testImage";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";

import "react-inner-image-zoom/lib/InnerImageZoom/styles.min.css";
import InnerImageZoom from "react-inner-image-zoom";
import Slider from "react-slick";

export const HeadingProduct = styled(Typography)({
  fontSize: "24px",
  fontWeight: "600",
  lineHeight: "29.26px",
  maxWidth: "607px",

  "@media (max-width: 600px)": {
    padding: "50px",
  },
});

export const ListItem = styled("li")({
  fontSize: "18px",
  fontWeight: "400",
  color: "#505050",
  lineHeight: "5px",
  display: "flex",
  marginBottom: "20px",
});

export const NavbarNav = styled("ul")({
  listStyle: "disc",
  padding: "0 20px",
  margin: "8px",
  color: "#505050",
});

export const ImageContainer = styled(Box)({
  background: "#FBFBFB",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  borderRadius: "4px",
  padding: "2px",
});

const Image = styled("img")`
  ${(props: { source: string }) => css`
    content: url(${props.source});
  `}
`;

const Target = styled(Image)`
  ${(props: { offset: { left: number; top: number }; opacity: number }) => css`
    position: absolute;
    left: ${props.offset.left}px;
    top: ${props.offset.top}px;
    opacity: ${props.opacity};
  `}
`;

var settings = {
  dots: true,
  infinite: true,
  speed: 200,
  slidesToShow: 1,
  slidesToScroll: 1,
};
const ProductDetails = () => {
  const { card } = colors;
  const refImg = useRef<HTMLImageElement | null | any>(null);
  const refImgBack = useRef<HTMLImageElement | null>(null);
  const { product, loading, error } = useSelector(
    (state: RootState) => state.product
  );
console.log("product",product);

  const navigate = useNavigate();

  const param = useParams();
  const dispatch = useDispatch<AppDispatch>();

  const changeCateID = () => {
    navigate("/shop");
    dispatch(setBread(product?.category?.name));
    dispatch({
      type: "products/setCategoryID",
      payload: [product?.category?.id],
    });
  };

  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: product?.category?.name,
      link: "/shop",
      active: false,
      onClick: () => changeCateID(),
    },
    { label: product?.name, link: `/shop/${param?.id}`, active: true },
  ];
  const [zoomScale, setZoomScale] = useState(1);
  const [position, setPosition] = useState({ x: 0, y: 0 });
  const scale = 2;
  // handleAddToCompare
  const handleCompare = useCompare();

  const sourceRef = useRef<HTMLImageElement>(null);
  const targetRef = useRef<HTMLImageElement>(null);
  const containerRef = useRef<HTMLDivElement>(null);

  const [opacity, setOpacity] = useState(0);
  const [offset, setOffset] = useState({ left: 0, top: 0 });

  const handleMouseEnter = () => {
    setOpacity(1);
  };

  const handleMouseLeave = () => {
    setOpacity(0);
  };

  const handleMouseMove = (e: React.MouseEvent) => {
    if (sourceRef.current && targetRef.current && containerRef.current) {
      const targetRect = targetRef.current.getBoundingClientRect();
      const sourceRect = sourceRef.current.getBoundingClientRect();
      const containerRect = containerRef.current.getBoundingClientRect();

      const xRatio =
        (targetRect.width - containerRect.width) / sourceRect.width;
      const yRatio =
        (targetRect.height - containerRect.height) / sourceRect.height;

      const left = Math.max(
        Math.min(e.pageX - sourceRect.left, sourceRect.width),
        0
      );
      const top = Math.max(
        Math.min(e.pageY - sourceRect.top, sourceRect.height),
        0
      );

      setOffset({
        left: left * -xRatio,
        top: top * -yRatio,
      });
    }
  };

  const handleZoomOnHover = (event: any) => {
    if (event.deltaY > 0) {
      // Scrolling down - zoom out
      setZoomScale((prevScale) => Math.max(1, prevScale - 0.1));
    } else {
      // Scrolling up - zoom in
      setZoomScale((prevScale) => prevScale + 0.1);
    }
  };

  useEffect(() => {
    dispatch(RequestGetProduct(param.id));
  }, [param.id]);
  useEffect(() => {}, [error]);
  return (
    <>
      {/* <TopNav /> */}
      {/* <SecondNav /> */}
      {/* <LastNav /> */}
      <Container>
        <Box >
          <BreadCrumbs crumbs={crumbs} />
        </Box>

        <Box >
          <Grid container sx={{ paddingTop: "20px" }} spacing={5}>
            <Grid item xs={12} sm={12} md={5}>
              <Box sx={{ height: "100%", position: "relative" }}>
                <Box sx={{ position: "sticky", top: "137px" }}>
                  {/* <TestImage /> */}
                  {loading ? (
                    <>
                      <Skeleton
                        variant="rectangular"
                        width="100%"
                        height={250}
                        sx={{
                          textAlign: "center",
                          display: "block",
                          margin: "auto",
                        }}
                      />
                      <Skeleton variant="text" width="80%" />
                      <Skeleton variant="text" width="80%" />
                    </>
                  ) : (
                    <div className="slider-container">
                      <Slider {...settings}>
                        <div style={{ width: "100%" }}>
                          <img
                            src={product?.image}
                            width={"100%"}
                            height={"400px"}
                            alt="product"
                          />
                        </div>
                        
                        {product?.additional_images?.map((image: any) => {
                          return (
                            <>
                              <div
                                style={{ width: "100%" }}
                                key={image?.id}
                                // sx={{
                                //   height: "94px",
                                //   img: {
                                //     width: "100%",
                                //     height: "100%",
                                //     objectFit: "contain",
                                //   },
                                // }}
                                id={image?.id}
                                onClick={(e) => {
                                  if (refImg.current) {
                                    refImg.current.src = image?.product_image;
                                  }
                                }}
                              >
                                <img
                                  width={"100%"}
                                  height={"400px"}
                                  src={image?.product_image}
                                  alt="test"
                                />
                              </div>
                            </>
                          );
                        })}
                        <div style={{ width: "100%" }}>
                          <img
                            src={product?.image}
                            width={"100%"}
                            height={"400px"}
                            alt="product"
                          />
                        </div>
                      </Slider>
                    </div>
                  )}
                  <Box
                    sx={{
                      display: "grid",
                      gridTemplateColumns: "repeat(4, 1fr)",
                      gap: "20px",
                      justifyContent: "flex-start",
                    }}
                  >
                    {/* <ImageContainer
                      sx={{
                        height: "94px",
                        img: {
                          width: "100%",
                          height: "100%",
                          objectFit: "contain",
                        },
                      }}
                      onClick={(e) => {
                        if (refImg.current) {
                          refImg.current.src = product?.image;
                        }
                      }}
                    >
                      <img src={product?.image} alt="test" />
                    </ImageContainer> */}
                  </Box>
                </Box>
              </Box>
            </Grid>
            <Grid item xs={12} sm={12} md={7}>
              <ProductsInfo product={product} />
            </Grid>
          </Grid>
        </Box>

        {/* offer details */}
        {/* <OfferDetails /> */}

        {/* REVIEW  */}
        {/* {product.reviews} */}

        <Box sx={{ mx: { md: "40px" } }}>
          <ReviewSection reviews={product?.product_reviews} product={product} />
        </Box>

        {/* <Box sx={{ direction: i18n.language === "ar" ? "rtl" : "ltr" }}>
          <AlertResponse type="error" message={error} />
        </Box> */}

        {/* Recommend Section */}
        <Box sx={{ mx: { md: "40px" } }}>
          <RecommendSection heading={t("Products you may Like")} />
        </Box>
      </Container>
      <Footer />
    </>
  );
};

export default ProductDetails;
