import { Box, Stack, Typography, Grid, Container } from "@mui/material";
import React from "react";
import { colors } from "../../utils/theme";
import { BiMessageDetail } from "react-icons/bi";
import { FaPhone } from "react-icons/fa";

import styled from "@emotion/styled";
import { t } from "i18next";
import { Link } from "react-router-dom";

const CircleIcon = styled(Box)({
  width: "40px",
  height: "40px",
  border: "1px solid #505050",
  borderRadius: "50%",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
});

const FooterProfile = () => {
  const { orange } = colors;
  return (
    <Box
      sx={{
        background: orange[800],
        padding: "24px",
      }}
    >
      <Container>
        <Grid container alignItems={"center"} spacing={3}>
          <Grid item xs={12} md={12} lg={6}>
            <Box>
              <Typography
                sx={{ fontSize: "18px", fontWeight: "600", mb: "12px" }}
              >
               {t('forhelpyou')}
              </Typography>
              <Typography sx={{ fontSize: "16px", fontWeight: "400" }}>
                {t('talk_to_us')}
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", gap: "16px",wordBreak:"break-all" }}>
              <CircleIcon>
              <svg xmlns="http://www.w3.org/2000/svg" width="17" height="12" viewBox="0 0 17 12" fill="none">
                <path d="M17.0002 10.5V1.5C17.0002 0.67 16.3302 0 15.5002 0H1.49023C0.660234 0 -0.00976562 0.67 -0.00976562 1.5V10.5C-0.00976562 11.33 0.660234 12 1.49023 12H15.5002C16.3302 12 17.0002 11.33 17.0002 10.5ZM15.6902 1.39C16.0202 1.72 15.8402 2.06 15.6602 2.23L11.6002 5.95L15.5002 10.01C15.6202 10.15 15.7002 10.37 15.5602 10.52C15.4302 10.68 15.1302 10.67 15.0002 10.57L10.6302 6.84L8.49023 8.79L6.36023 6.84L1.99023 10.57C1.86023 10.67 1.56023 10.68 1.43023 10.52C1.29023 10.37 1.37023 10.15 1.49023 10.01L5.39023 5.95L1.33023 2.23C1.15023 2.06 0.970234 1.72 1.30023 1.39C1.63023 1.06 1.97023 1.22 2.25023 1.46L8.49023 6.5L14.7402 1.46C15.0202 1.22 15.3602 1.06 15.6902 1.39Z" fill="#505050"/>
              </svg>
              </CircleIcon>
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                  {t('emailsupport')}
                </Typography>
                <Link to={'mailto:rafalelectric.com'} target="_blank" style={{ fontSize: "18px", fontWeight: "600",textDecoration:'none',color:"#000" }}>
                  rafalelectric.com
                </Link>
              </Box>
            </Box>
          </Grid>
          <Grid item xs={12} md={6} lg={3}>
            <Box sx={{ display: "flex", gap: "16px" }}>
              <CircleIcon>
                <FaPhone />
              </CircleIcon>
              <Box>
                <Typography sx={{ fontSize: "14px", fontWeight: "500" }}>
                {t('mobile_number')}
                </Typography>
                <Typography sx={{ fontSize: "18px", fontWeight: "600" }}>
                 <Link to="tel:+201010628457" style={{textDecoration:"none",color:"#0E0F1D"}}>
                  {t('phone_en_ar')}
                 </Link>
                </Typography>
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </Box>
  );
};

export default FooterProfile;
