import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCatego } from "../../store/products/categorySlice";
import { useNavigate } from "react-router-dom";
import { setBread } from "../../store/products/productsSlice";

function CategoryThree() {
  const category = useSelector((state) => state.categorySection.category);
  const dispatch = useDispatch();
  const Navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    dispatch(GetCatego());
  }, [dispatch]);

  const cat = category?.section3;
  console.log("cat", cat);

  return (
    <Box>
      {cat && (
        <Box>
          <Grid
            container
            sx={{
              marginBlock: "50px",
              width: "100%",
              height: "500px",
            }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{  height: "500px" ,width: "100%",}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Navigate(`/category/${cat[0].id}`, {
                    state: { category_id: cat[0].id },
                  });
                  dispatch(setBread(cat[0].name));
                  dispatch({
                    type: "products/setCategoryID",
                    payload: [cat[0].id],
                  });
                }}
              >
                <img
                  className="radiusimg"
                  width={"100%"}
                  height={"100%"}
                  src={cat[0]?.cat_image}
                  alt=""
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{  height: "500px" ,width: "100%",}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  width: "100%",
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Navigate(`/category/${cat[1].id}`, {
                      state: { category_id: cat[1].id },
                    });
                    dispatch(setBread(cat[1].name));
                    dispatch({
                      type: "products/setCategoryID",
                      payload: [cat[1].id],
                    });
                  }}
                >
                  <img
                    className="radiusimg"
                    width={"100%"}
                    height={"100%"}
                    src={cat[1].cat_image}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Navigate(`/category/${cat[2].id}`, {
                      state: { category_id: cat[2].id },
                    });
                    dispatch(setBread(cat[2].name));
                    dispatch({
                      type: "products/setCategoryID",
                      payload: [cat[2].id],
                    });
                  }}
                >
                  <img
                    className="radiusimg"
                    width={"100%"}
                    height={"100%"}
                    src={cat[2].cat_image}
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                sx={{ marginTop: "20px", cursor: "pointer" }}
                onClick={() => {
                  Navigate(`/category/${cat[3].id}`, {
                    state: { category_id: cat[3].id },
                  });
                  dispatch(setBread(cat[3].name));
                  dispatch({
                    type: "products/setCategoryID",
                    payload: [cat[3].id],
                  });
                }}
              >
                <img
                  className="radiusimg"
                  width={"100%"}
                  height={"260px"}
                  src={cat[3].cat_image}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      )}
    </Box>
  );
}

export default CategoryThree;
