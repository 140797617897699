// import { BASEURL } from '@/data/APIS';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BASEURL } from "../../data/apis";
import customFetch from "../../utils/axios";
import Cookies from "js-cookie";
import { toast } from "react-toastify";
import { t } from "i18next";

const getLocalStoargeDetails = () => {
  let user = null;

  const result = localStorage.getItem("userDetails");
  user = result ? JSON.parse(result) : null;

  return user;
};

export interface UserState {
  loading: boolean;
  error: boolean;
  user: any;
  errorMsg: string;
  errorVerfiy: string;
  errorLogin: string;
  errorReset: string;
  errorForgot: string;
  errorChange: string;
  errorVerfiyForget: string,
  userDetails: any;
  errorSignUp: string;
}

const initialState: UserState = {
  loading: false,
  user: Cookies.get("token") ? Cookies.get("token") : null,
  error: false,
  errorMsg: "",
  errorSignUp: "",
  errorVerfiy: "",
  errorLogin: "",
  errorReset: "",
  errorForgot: "",
  errorChange: "",
  errorVerfiyForget: "",
  userDetails: getLocalStoargeDetails(),
};

export const phoneWithoutZero = (phone: any) => {
  let newPhone;
  if (phone[0] === "0") {
    newPhone = `+20${phone.slice(1)}`;
  } else {
    newPhone = `+20${phone}`;
  }
  return newPhone;
};

export const RequestPostRegister = createAsyncThunk(
  "RequestPostRegister",
  async (data: any, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/register/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);



export const RequestPostLogin = createAsyncThunk(
  "RequestPostLogin",
  async (data: { password: string; phone: string }, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/login/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      
      ThunkApi.dispatch({ type: "guestCart/removeCartID" });
      ThunkApi.dispatch({ type: "guestCart/clearCart" });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

// api/sendotp/

export const RequestSendOtp = createAsyncThunk(
  "RequestSendOtp",
  async (data: { phone: string }, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/sendotp/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);


export const RequestForgotPassword = createAsyncThunk(
  "RequestForgotPassword",
  async (data: { phone: string }, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/forgetpassword/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);



export const RequestVerifyPassword = createAsyncThunk(
  "RequestVerifyPassword",
  async (data: { phone?: any; otp: string; action: string}, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/verifyotp/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      ThunkApi.dispatch({ type: "guestCart/removeCartID" });
      ThunkApi.dispatch({ type: "guestCart/clearCart" });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const RequestVerifyChangePassword = createAsyncThunk(
  "RequestVerifyChangePassword",
  async (data: { phone?: any; otp: string; action: string}, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/verifyotp/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const RequestVerifyPhone = createAsyncThunk(
  "RequestVerifyPhone",
  async (data: { phone?: any; otp: string; new_phone?:any}, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/verifyphone/`, {
        ...data, new_phone: `+20${data.phone}`
      });
      ThunkApi.dispatch({type:"guestCart/removeCartID"})
      ThunkApi.dispatch({type:"guestCart/clearCart"})
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const RequestResetPassword = createAsyncThunk(
  "RequestResetPassword",
  async (data: { new_password: string; phone: any }, ThunkApi) => {
    try {
      const response = await axios.post(`${BASEURL}/api/resetpassword/`, {
        ...data,
        phone: phoneWithoutZero(data.phone),
        otp: localStorage.getItem('otp')
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const RequestChangePassword = createAsyncThunk(
  "RequestChangePassword",
  async (data: { new_password: string; old_password: string }, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token: string = state.user.user;
    try {
      const response = await axios.put(
        `${BASEURL}/api/changepassword/`,
        {
          ...data,
        },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUser: (state) => {
      state.user = null;
      state.userDetails = null;
    },
    setErrorMsg: (state, action) => {
      state.errorMsg = action.payload;
    },
    setUserDetails: (state, action) => {
      state.userDetails = action.payload
    }
  },
  extraReducers: (builder) => {
    //register
    builder
      .addCase(RequestPostRegister.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.user = null;
        state.errorSignUp = "";
      })
      .addCase(RequestPostRegister.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(t('user_registerd'));
      })
      .addCase(RequestPostRegister.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorSignUp = action?.payload?.message;
        toast.error(action?.payload?.message);
        toast.error(action?.payload?.phone[0]);
      });

    // login
    builder
      .addCase(RequestPostLogin.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.user = null;
        state.errorLogin = "";
      })
      .addCase(RequestPostLogin.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
        state.user = action.payload.token;
        toast.success(t('user_login'))
        // Cookies.set("token", action.payload.token);
      })
      .addCase(RequestPostLogin.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorLogin = action?.payload?.message;
        toast.error(action?.payload?.message);
      });

    // verfiy password
    builder
      .addCase(RequestVerifyPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorVerfiy = "";
      })
      .addCase(RequestVerifyPassword.fulfilled, (state, action) => {
        state.loading = false;
        state.userDetails = action.payload;
        state.user = action.payload.token;
        Cookies.set("token", action.payload.token);
        localStorage.setItem("userDetails", JSON.stringify(action.payload));
        toast.success(t('user_verified'))
      })
      .addCase(RequestVerifyPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorVerfiy = action?.payload?.message;
        toast.error(action?.payload?.message);
      });

      builder
      .addCase(RequestVerifyChangePassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorVerfiyForget = "";
      })
      .addCase(RequestVerifyChangePassword.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(t('user_verified'))
      })
      .addCase(RequestVerifyChangePassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorVerfiyForget = action?.payload?.message;
        toast.error(action?.payload?.message);
      });

    // forgot password
    builder
      .addCase(RequestForgotPassword.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorForgot = "";
      })
      .addCase(RequestForgotPassword.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(t('user_foregot'))
      })
      .addCase(RequestForgotPassword.rejected, (state, action: any) => {
        state.loading = false;
        state.error = true;
        state.errorForgot = action?.payload?.message;
        state.errorMsg = action?.payload?.message;
        toast.error(action?.payload?.message);
      });


    // send Otp
    builder
      .addCase(RequestSendOtp.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.errorForgot = "";
      })
      .addCase(RequestSendOtp.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(t('user_foregot'))
      })
      .addCase(RequestSendOtp.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action?.payload?.message);
      });

    // change password
    builder
    .addCase(RequestChangePassword.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.errorChange = ""

    })
    .addCase(RequestChangePassword.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMsg = "";
      toast.success(t('password_changed'))
    })
    .addCase(RequestChangePassword.rejected, (state, action: any) => {
      state.loading = false;
      state.error = true;
      state.errorChange = action?.payload?.message
      toast.error(action?.payload?.message)
    }); 

    // Reset Password
    builder
    .addCase(RequestResetPassword.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.errorReset = "";
    })
    .addCase(RequestResetPassword.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMsg = "";
      toast.success(t('reset_password_done'))
    })
    .addCase(RequestResetPassword.rejected, (state, action: any) => {
      state.loading = false;
      state.errorReset = action?.payload?.message
      state.error = true;
      toast.error(action?.payload?.message)
    }); 

    //verify phone edit
    builder
    .addCase(RequestVerifyPhone.pending, (state) => {
      state.loading = true;
      state.error = false;
      state.errorChange = ""

    })
    .addCase(RequestVerifyPhone.fulfilled, (state, action) => {
      state.loading = false;
      state.errorMsg = "";
      toast.success(t('phone_changed'))
    })
    .addCase(RequestVerifyPhone.rejected, (state, action: any) => {
      state.loading = false;
      state.error = true;
      state.errorChange = action?.payload?.message
      toast.error(action?.payload?.message)
    }); 
  },
});

// Action creators are generated for each case reducer function
export const { setUser, setErrorMsg, setUserDetails } = userSlice.actions;

export default userSlice.reducer;
