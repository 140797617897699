import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography, OutlinedInput } from "@mui/material";
import PasswordField from "../common/passwordField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MainButton } from "../../style/style";
import Login from "../sign/login";
import ForgotPassword from "../sign/forgotPassword";
import VerfiyMobile from "../sign/verifyMobile";
import Reset from "../sign/reset";
import SideCartEmpty from "./sideCartEmpty";
import SideCartProducts from "./sideCartProducts";

import useMediaQuery from "@mui/material/useMediaQuery";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SideCartDialog({ open, onClose }: any) {
  const [state, setState] = React.useState({
    right: false,
    left: false
  });

  const lang = useSelector((state: RootState) => state.global.mainLang)

  // tokens
  const token = useSelector((state: RootState) => state.user.user);
  const guestToken = useSelector((state: RootState) => state.guestCart.cartID);

  // items
  const items = useSelector((state: RootState) => state.cart.items);
  const AllItems = useSelector((state: RootState) => state.cart.all_items);
  const guestItems = useSelector((state: RootState) => state.guestCart.items);
  const guestAllItems = useSelector((state: RootState) => state.guestCart.all_items);
  

  // responsive
  const matches = useMediaQuery("(max-width:600px)");

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: matches ? "100%" : "500px",
        padding: "32px",
        height: "100%",
        direction: lang === "en" ? "ltr" : "rtl" 
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ paddingTop: "130px", height:"100%" }}>
        {token && items.length > 0 ? (
          <SideCartProducts items={items} total_products_price={AllItems?.total_amount}/>
        ) : guestToken && guestItems.length > 0 ? (
          <SideCartProducts items={guestItems} total_products_price={guestAllItems?.total_amount}/>
        ) : (
          <SideCartEmpty />
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      {(["right"] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            dir={lang == "ar" ? "rtl": "ltr"}
            anchor={anchor}
            open={open}
            onClose={onClose}
            disableScrollLock={true}
            sx={{
              "&.MuiModal-root": {
                zIndex: "11",
                top: "80px",
              },
              "& .MuiModal-backdrop": {
                background: "rgba(27, 27, 27, 0.10) !important",
              },
            }}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
  );
}
