import React from "react";

import { Box, Typography } from "@mui/material";
import { colors } from "../../utils/theme";
import { trashCartIcon } from "../../assets/svg/svg";
import { Link } from "react-router-dom";
import useRemoveItem from "../../hooks/useRemoveItem";
import { t } from "i18next";
import AmountQty from "../productDetails/amountQty";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const ProductCart = ({ item, isDelete = false, isQty = false, isOrder = false, order_id }: any) => {

    const handleRemoveItem = useRemoveItem();

    const order = useSelector((state: RootState) => state.orders.order);

    return (<Box sx={{}}>
        <Box sx={{ display: "flex", gap: "19px" }}>
            <Box sx={{ position: "relative" }}>
                <Link to={`/shop/${item.product?.id}`}>
                    <img
                        src={item?.product?.image}
                        alt="product"
                        style={{ width: "148px", height: "148px" }}
                    />
                </Link>
                <Typography
                    component={"span"}
                    sx={{
                        position: "absolute",
                        top: "0px",
                        background: "#F5841F",
                        width: "22px",
                        height: "22px",
                        borderRadius: "50%",
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        right: "0",
                        fontWeight: "600",
                        fontSize: "12px",
                    }}
                >
                    {item?.quantity}
                </Typography>
            </Box>
            <Box flex="1">
                {isDelete && (<Box
                    sx={{ display: "flex", justifyContent: "end", cursor: "pointer" }}
                    onClick={() => handleRemoveItem(item?.product?.id)}
                >
                    {trashCartIcon}
                </Box>)}

                <Typography
                    className="text-3"
                    sx={{
                        fontSize: { xs: "14px", sm: "16px", lg: "18px" }, fontWeight: "600",
                    }}
                >
                    {item?.product?.name}
                </Typography>

                <Box sx={{ mt: "10px" }}>
                    <Typography
                        component={"span"}
                        sx={{
                            color: colors.blue[100],
                            fontWeight: "600",
                            fontSize: { xs: "14px", sm: "14px", lg: "16px" },
                        }}
                    >
                        {item?.product?.price} {t("egp")}
                    </Typography>
                    <Typography
                        component={"span"}
                        sx={{
                            ml: "24px",
                            mr: "24px",
                            textDecoration: "line-through",
                            fontWeight: "400",
                            fontSize: { xs: "14px", sm: "14px", lg: "16px" },
                        }}
                    >
                        {item?.product?.old_price > 0 && item?.product?.old_price} {t("egp")}
                    </Typography>
                    <Box mt="15px">
                        {/*{isQty && (*/}
                        {/*  */}
                        {/*)}*/}
                        {
                            order?.order_status !== "Confirmed" && (
                                <AmountQty
                                    quantity={item.quantity}
                                    id={item?.product?.id}
                                    productQty={item?.product?.quantity}
                                    justifyContent="start"
                                    padding="0 10px"
                                    isOrder={isOrder}
                                    order_id={order_id}
                                />
                            )
                        }

                    </Box>
                </Box>
            </Box>
        </Box>
    </Box>);
};

export default ProductCart;
