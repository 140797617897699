import React, {useEffect, useState} from "react";
import {BiSolidDownArrow, BiSolidUpArrow} from "react-icons/bi";
import Box from "@mui/material/Box/Box";
import {t} from "i18next";
import {useDispatch} from "react-redux";
import {AppDispatch} from "../../store/store";
import useUpdateItem from "../../hooks/useUptateItem";
import {toast} from "react-toastify";
import {RequestUpdateOrder} from "../../store/order/orderSlice";

const AmountQty = ({
                       quantity,
                       id,
                       productQty,
                       justifyContent = "center",
                       padding = "8px 15px",
                       isOrder = false,
                       order_id,
                   }: any) => {
    const [quantityItem, setQuantityItem] = useState<any>("");

    useEffect(() => {
        if (quantity) setQuantityItem(quantity);
    }, [quantity]);

    const dispatch = useDispatch<AppDispatch>();

    const handleUpdateItem = useUpdateItem();

    return (
        <>
            <Box
                sx={{
                    display: "flex",
                    gap: "20px",
                    flexWrap: "wrap",
                    justifyContent: justifyContent, // shuould to be center || start
                }}
            >
                <Box
                    sx={{
                        display: "flex",
                        border: "0.5px solid #8A8A8A",
                        padding: padding,
                        borderRadius: "8px",
                        "& svg:hover": {
                            color: "#F5841F"
                        }
                    }}
                >
                    <input
                        type="number"
                        style={{
                            border: "none",
                            outline: "none",
                            width: "60px",
                            fontSize: "18px",
                            fontWeight: "600",
                            padding: "10px",
                        }}
                        placeholder="1"
                        value={quantityItem}
                        onChange={(e: any) => {
                            if(e.target.value <= 0 ) return e.target.value
                            setQuantityItem(parseInt(e.target.value));
                            isOrder ?
                                dispatch(RequestUpdateOrder({
                                    product_id: id,
                                    quantity: e.target.value,
                                    order_id: order_id,
                                })) :
                                handleUpdateItem(id, e.target.value)
                        }}
                    />
                    <Box
                        sx={{
                            display: "flex",
                            flexDirection: "column",
                            justifyContent: "center",
                        }}
                    >

                        <BiSolidUpArrow
                            style={{fontSize: "10px", cursor: "pointer"}}
                            onClick={(old) => {
                                if (quantity >= productQty) {
                                    toast.error(t("quantity-enough"));
                                    return false;
                                }
                                setQuantityItem(parseInt(quantityItem) + 1);
                                isOrder ?
                                    dispatch(RequestUpdateOrder({
                                        product_id: id,
                                        quantity: quantityItem + 1,
                                        order_id: order_id,
                                    }))
                                    :
                                    handleUpdateItem(id, quantityItem + 1)
                            }}

                        />
                        <BiSolidDownArrow
                            style={{fontSize: "10px", cursor: "pointer"}}
                            onClick={(old) => {
                                if (quantityItem === 1) return
                                setQuantityItem(parseInt(quantityItem) - 1);
                                if (quantityItem <= 1) {
                                    setQuantityItem(1);
                                }
                                isOrder ?
                                    dispatch(RequestUpdateOrder({
                                        product_id: id,
                                        quantity: quantityItem - 1,
                                        order_id: order_id,
                                    }))
                                    :
                                    handleUpdateItem(id, quantityItem - 1);
                                
                            }}
                        />
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default AmountQty;
