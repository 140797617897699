import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCatego } from "../../store/products/categorySlice";
import { useNavigate } from "react-router-dom";
import { setBread } from "../../store/products/productsSlice";

function CategoryOne() {
  const category = useSelector((state) => state.categorySection.category);
  const dispatch = useDispatch();
  const Navigate = useNavigate(); // Use the useNavigate hook

  useEffect(() => {
    dispatch(GetCatego());
  }, [dispatch]);

  const cat = category?.section1;

  return (
    <Box>
      {cat && (
        <Box>
          <Box
            sx={{
              marginTop: "50px",
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
             
            }}
            onClick={() => {
              Navigate(`/category/${category?.section1?.[0].id}`, {
                state: { category_id: category?.section1?.[0].id },
              });
              dispatch(setBread(category?.section1?.[0].name));
              dispatch({
                type: "products/setCategoryID",
                payload: [category?.section1?.[0].id],
              });
            }}
          >
            <img
            
              className="radiusimg"
              width={"100%"}
              height={"200px"}
              src={category?.section1?.[0].cat_image}
              alt=""
            />
          </Box>
          <Grid
            container
            sx={{
              marginBlock: "50px",
              width: "100%",
              height: "500px",
            }}
            spacing={2}
          >
            <Grid item xs={12} md={6} sx={{  height: "500px" ,width: "100%",}}>
              <Box
                sx={{
                  width: "100%",
                  height: "100%",
                  cursor: "pointer",
                }}
                onClick={() => {
                  Navigate(`/category/${category?.section1?.[1].id}`, {
                    state: { category_id: category?.section1?.[1].id },
                  });
                  dispatch(setBread(category?.section1?.[1].name));
                  dispatch({
                    type: "products/setCategoryID",
                    payload: [category?.section1?.[1].id],
                  });
                }}
              >
                <img
                  className="radiusimg"
                  width={"100%"}
                  height={"100%"}
                  src={category?.section1?.[1].cat_image}
                  
                  objectFit="cover"
                />
              </Box>
            </Grid>
            <Grid item xs={12} md={6} sx={{  height: "500px" ,width: "100%",}}>
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "15px",
                  width: "100%",
                 
                }}
              >
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Navigate(`/category/${category?.section1?.[2].id}`, {
                      state: { category_id: category?.section1?.[2].id },
                    });
                    dispatch(setBread(category?.section1?.[2].name));
                    dispatch({
                      type: "products/setCategoryID",
                      payload: [category?.section1?.[2].id],
                    });
                  }}
                >
                  <img
                    className="radiusimg"
                    width={"100%"}
                    height={"100%"}
                    src={category?.section1?.[2].cat_image}
                    alt=""
                  />
                </Box>
                <Box
                  sx={{
                    width: "100%",
                    height: "200px",
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    Navigate(`/category/${category?.section1?.[3].id}`, {
                      state: { category_id: category?.section1?.[3].id },
                    });
                    dispatch(setBread(category?.section1?.[3].name));
                    dispatch({
                      type: "products/setCategoryID",
                      payload: [category?.section1?.[3].id],
                    });
                  }}
                >
                  <img
                    className="radiusimg"
                    width={"100%"}
                    height={"100%"}
                    src={category?.section1?.[3].cat_image}
                    alt=""
                  />
                </Box>
              </Box>
              <Box
                sx={{ marginTop: "20px", cursor: "pointer" }}
                onClick={() => {
                  Navigate(`/category/${category?.section1?.[4].id}`, {
                    state: { category_id: category?.section1?.[4].id },
                  });
                  dispatch(setBread(category?.section1?.[4].name));
                  dispatch({
                    type: "products/setCategoryID",
                    payload: [category?.section1?.[4].id],
                  });
                }}
              >
                <img
                  className="radiusimg"
                  width={"100%"}
                  height={"260px"}
                  src={category?.section1?.[4].cat_image}
                  alt=""
                />
              </Box>
            </Grid>
          </Grid>
          <Box
            sx={{
              marginTop: "50px",
              paddingTop: "20px",
              textAlign: "center",
              width: "100%",
              cursor: "pointer",
            }}
            onClick={() => {
              Navigate(`/category/${category?.section1?.[5].id}`, {
                state: { category_id: category?.section1?.[5].id },
              });
              dispatch(setBread(category?.section1?.[5].name));
              dispatch({
                type: "products/setCategoryID",
                payload: [category?.section1?.[5].id],
              });
            }}
          >
            <img
              className="radiusimg"
              width={"100%"}
              height={"400px"}
              src={category?.section1?.[5].cat_image}
              alt=""
            />
          </Box>
        </Box>
      )}
    </Box>
  );
}

export default CategoryOne;
