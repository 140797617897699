import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import axios from "axios";
import {BASEURL} from "../../data/apis";
import Cookies from "js-cookie";

import {toast} from "react-toastify";
import {t} from "i18next";
import {setOpenDrawerCart} from "../globalSlice";

interface TotalState {
    shipping_price?: number | null;
    total_products_price: number | null;
    delivery_total_price: number | null;

}

export interface GuestCartState {
    loading: boolean;
    items: any;
    all_items: any;
    cartID: any;
    mainCartId: any;
    cartUser: TotalState;
}

const initialState: GuestCartState = {
    loading: false,
    items: [],
    all_items: [],
    cartID: Cookies.get("sessionid") || null,
    mainCartId: Cookies.get("mainCartId") || null,
    cartUser: {
        shipping_price: 0,
        total_products_price: 0,
        delivery_total_price: 0,
    },
};

export const RequestGetGuestCart = createAsyncThunk(
    "RequestGetGuestCart",
    async (data, {getState, rejectWithValue}) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        const response = await axios.get(`${BASEURL}/cart/?session_key=${cartID}`);
        return response.data;
    }
);

export const RequestPostToGuestCart = createAsyncThunk(
    "RequestPostToGuestCart",
    async (data: any, {getState, rejectWithValue, dispatch}) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;
        try {
            const response = await axios.post(
                `${BASEURL}/cart/add-to-cart/${data.product_id}/`
                , {
                    session_key: cartID
                }
            );
             dispatch(setOpenDrawerCart(true))
            return response.data;
        } catch (error: any) {
            return rejectWithValue(error.response.data);
        }
    }
);

export const RequestDeleteGuestItem = createAsyncThunk(
    "RequestDeleteGuestItem",
    async (id: any, {getState, rejectWithValue}) => {
        const state: any = getState();
        const cartID = state.guestCart.cartID;

        const response = await axios.delete(
            `${BASEURL}/cart/${Cookies.get("mainCartId")}/?session_key=${cartID}`,
            {
                data: {
                    "product_id": id
                }
            }
        );
        return {res: response.data, id: id};
    }
);

export const RequestUpdateGuestItem = createAsyncThunk(
    "RequestUpdateGuestItem",
    async ({quantity, id}: any, {getState, rejectWithValue}) => {
        const state: any = getState();
        const token: string = state.user.user;
        const cartID = state.guestCart.cartID;
        const response = await axios.patch(
            `${BASEURL}/cart/${Cookies.get("mainCartId")}/?session_key=${cartID}`,
            {
                "product_id": id,
                "quantity": quantity
            }
        );
        return {res: response.data, quantity, id};
    }
);

export const guestCartSlice = createSlice({
    name: "guestCart",
    initialState,
    reducers: {
        removeCartID: (state, action) => {
            state.cartID = null;
            Cookies.remove("sessionid");
        },
        clearCart: (state, action) => {
            state.items = [];
            state.cartUser = {
                shipping_price: null,
                total_products_price: null,
                delivery_total_price: null,
            };
        },
    },
    extraReducers: (builder) => {
        // Request GET CART
        builder
            .addCase(RequestGetGuestCart.pending, (state) => {
                state.loading = true;
                state.items = [];
                state.all_items = [];
            })
            .addCase(RequestGetGuestCart.fulfilled, (state, action) => {
                state.loading = false;
                state.items = action.payload?.at(0)?.items || [];
                state.all_items = action.payload?.at(0) || [];

                // ------------- Add Item to Cookies -------------
                const Guest_Token: any = Cookies.get("sessionid");
                !Guest_Token && Cookies.set("sessionid", action.payload?.session_key, {expires: 7});
                Cookies.set("mainCartId", action.payload?.at(0)?.id, {expires: 7});

                let sumTotal = state?.items
                    ?.map((item: any) => item?.quantity * item?.product?.price)
                    .reduce((total: any, current: any) => total + current, 0);

                state.cartUser.total_products_price = sumTotal;
                state.cartUser.delivery_total_price =
                    sumTotal + state.cartUser.shipping_price;
            })
            .addCase(RequestGetGuestCart.rejected, (state, action: any) => {
                state.loading = false;
                state.items = [];
            });

        // ADD Items To Cart
        builder
            .addCase(RequestPostToGuestCart.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestPostToGuestCart.fulfilled, (state, action) => {
                state.loading = false;


                // ------------- Add Item to Cookies -------------
                const Guest_Token: any = Cookies.get("sessionid");
                !Guest_Token && Cookies.set("sessionid", action.payload?.session_key, {expires: 7});
                Cookies.set("mainCartId", action.payload?.id, {expires: 7});

                !Guest_Token && (state.cartID = action?.payload.session_key);
                state.mainCartId = action?.payload.id
                state.items = action.payload?.items;
                state.all_items = action.payload;

                let sumTotal = state.items
                    .map((item: any) => item?.quantity * item?.product?.price)
                    .reduce((total: any, current: any) => total + current, 0);

                state.cartUser.total_products_price = sumTotal;
                state.cartUser.delivery_total_price =
                    sumTotal + state.cartUser.shipping_price;


                toast.success(t("added_item"));
            })
            .addCase(RequestPostToGuestCart.rejected, (state, action: any) => {
                state.loading = false;
                toast.error(action?.payload);
            });

        // Delete Item
        builder
            .addCase(RequestDeleteGuestItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestDeleteGuestItem.fulfilled, (state, action) => {
                state.loading = false;

                // delete item from cart --
                state.items = action?.payload?.res?.items || [];
                state.all_items = action?.payload?.res || [];
                let sumTotal = state?.items
                    .map((item: any) => item?.quantity * item?.product?.price)
                    .reduce((total: any, current: any) => total + current, 0) || 0;

                state.cartUser.total_products_price = sumTotal;
                state.cartUser.delivery_total_price =
                    sumTotal + state.cartUser.shipping_price;
                toast.info(t("removed_item"));
            })
            .addCase(RequestDeleteGuestItem.rejected, (state, action: any) => {
                state.loading = false;
            });

        // update Item
        builder
            .addCase(RequestUpdateGuestItem.pending, (state) => {
                state.loading = true;
            })
            .addCase(RequestUpdateGuestItem.fulfilled, (state, action) => {
                const {quantity, id, res} = action.payload;

                state.loading = false;

                // state.cartUser.delivery_total_price =
                // action.payload.res.delivery_total_price;

                // update quantity --
                state.items = state.items.map((item: any) => {
                    return item.id === id
                        ? {...item, quantity, subtotal: res.subtotal}
                        : item;
                });
                state.all_items = action.payload?.res;

                // Sum Total --
                const sumTotal = state.items
                    .map((item: any) => item.subtotal)
                    .reduce((total: any, current: any) => total + current);

                state.cartUser.total_products_price = sumTotal;
                state.cartUser.delivery_total_price =
                    sumTotal + state.cartUser.shipping_price;

            })
            .addCase(RequestUpdateGuestItem.rejected, (state, action: any) => {
                state.loading = false;
            });
    },
});

export const {removeCartID, clearCart} = guestCartSlice.actions;

export default guestCartSlice.reducer;
