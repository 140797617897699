import React, { useRef, useEffect, useState } from "react";
import { H2 } from "../../style/style";
import { Box, Typography, Grid, Skeleton } from "@mui/material";
import recommendImage from "../../assets/home/recommed-1.png";
import StarBorderIcon from "@mui/icons-material/StarBorder";
import { colors } from "../../utils/theme";
import StarIcon from "@mui/icons-material/Star";
import ProductCard from "../common/productCard";
import Slider from "react-slick";

import productImage from "../../assets/recommend/product-6.png";
import productImage2 from "../../assets/recommend/product-2.png";
import productImage3 from "../../assets/recommend/product-3.png";
import productImage4 from "../../assets/recommend/product-4.png";
import productImage5 from "../../assets/recommend/product-5.png";

import { BsChevronRight } from "react-icons/bs";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetLike,
  RequestGetRecommended,
} from "../../store/recommended/recommendedSlice";
import { t } from "i18next";

interface RecommendedState {
  heading?: string;
  fetchUrl?: string;
}

const LikeYouSection = ({
  heading = t("You may also like"),
  fetchUrl = "/likedproducts/",
}: RecommendedState) => {
  const dispatch = useDispatch<AppDispatch>();
  const { dark, blue } = colors;
  const { like, loading, likeLoading } = useSelector(
    (state: RootState) => state.recommended
  );
  const settings = {
    className: "serv",
    infinite: true,
    slidesToShow: 6,
    slidesToScroll: 2,
    speed: 1000,
    responsive: [
      {
        breakpoint: 1600,
        settings: {
          slidesToShow: 5,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          initialSlide: 2,
          infinite: true,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
        },
      },
    ],
    // nextArrow: <SampleNextArrow />,
    // prevArrow: <SamplePrevArrow />,
  };

  const arrowRef = useRef<Slider>(null);

  // Add state variable to track the current slide index
  const [currentSlideIndex, setCurrentSlideIndex] = useState(0);

  const handlePrevClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickPrev();
      setCurrentSlideIndex(currentSlideIndex - 1);
    }
  };

  const handleNextClick = (): void => {
    if (arrowRef.current !== null) {
      arrowRef.current.slickNext();
      setCurrentSlideIndex(currentSlideIndex + 1);
    }
  };

  useEffect(() => {
    dispatch(RequestGetLike(fetchUrl));
    // console.log(recommended);
  }, []);
  return (
    <div style={{ marginTop: "50px" ,paddingTop:"50px"}}  data-toggle='drafts' id="drafts">
      <H2 variant="h2" mb="24px" mt="48px">
        {heading}
      </H2>

      <div style={{ position: "relative" }}>
        {likeLoading ? (
          <Slider {...settings} ref={arrowRef}>
            {Array.from(new Array(12)).map((item) => {
              return (
                <Box>
                  <Skeleton
                    variant="rectangular"
                    width="100%"
                    height={250}
                    sx={{
                      textAlign: "center",
                      display: "block",
                      margin: "auto",
                    }}
                  />
                  <Skeleton variant="text" width="100%" />
                </Box>
              );
            })}
          </Slider>
        ) : (
          <Slider {...settings} ref={arrowRef}>
            {like?.map((product: any) => (
              <Box>
                <ProductCard type="" image={productImage} product={product} />
              </Box>
            ))}
          </Slider>
        )}

        <div className="slider-arrow">
          {currentSlideIndex > 0 && (
            <button onClick={handlePrevClick} className="back">
              <NavigateBeforeIcon />
            </button>
          )}
          <button onClick={() => handleNextClick()} className="next">
            <NavigateNextIcon />
          </button>
        </div>
      </div>
    </div>
  );
};

export default LikeYouSection;
