import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import axios from "axios";
import { BASEURL } from "../../data/apis";
import { toast } from "react-toastify";
import { t } from "i18next";
import { phoneWithoutZero } from "../user/user";

export interface ProfileState {
  profile: any;
  otp: string;
  loading: boolean;
  error: boolean;
  customer: any;
}

const initialState: ProfileState = {
  profile: {},
  otp: "",
  loading: false,
  error: false,
  customer: {},
};

export const RequestGetProfile = createAsyncThunk(
  "RequestGetProfile",
  async (_, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      const response = await axios.get(`${BASEURL}/profile/`, {
        headers: {
          Authorization: `Token ${token}`,
        },
      });
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);
export const RequestEditProfilename = createAsyncThunk(
  "RequestEditProfilename",
  async (data: any, ThunkApi) => {

    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      const response = await axios.put(
        `${BASEURL}/api/user-profile/`,
        { ...data, phone: phoneWithoutZero(data.phone) },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);
export const RequestEditProfilephone = createAsyncThunk(
  "RequestEditProfilephone",
  async (data: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    try {
      const response = await axios.put(
        `${BASEURL}/api/changephone/`,
        { ...data, phone: phoneWithoutZero(data.phone) },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

// 01151417689

export const RequestUpdateCustomer = createAsyncThunk(
  "RequestUpdateCustomer",
  async (data: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const token = state.user.user;
    if (data.phone === null) {
      data = {first_name: data?.first_name}
    } else {
      data = {...data, phone: phoneWithoutZero(data.phone)}
    }
    try {
      const response = await axios.put(
        `${BASEURL}/api/user-profile/`,
        { ...data },
        {
          headers: {
            Authorization: `Token ${token}`,
          },
        }
      );
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.response.data);
    }
  }
);

export const profileSlice = createSlice({
  name: "profile",
  initialState,
  reducers: {
    setValueUser: (state, { payload }) => {
      const { name, value }: any = payload;
      state.customer[name] = value;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RequestGetProfile.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.customer = {};
      })
      .addCase(RequestGetProfile.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
        state.customer = {
          ...action.payload,
          phone: action?.payload?.phone.slice(3),
        };
      })
      .addCase(RequestGetProfile.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
    builder
      .addCase(RequestEditProfilename.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(RequestEditProfilename.fulfilled, (state, action) => {
        state.loading = false;
        state.profile = action.payload;
      })
      .addCase(RequestEditProfilename.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
    builder
      .addCase(RequestEditProfilephone.pending, (state) => {
        state.loading = true;
        state.error = false;
      })
      .addCase(RequestEditProfilephone.fulfilled, (state, action) => {
        state.loading = false;
        toast.success(t("verify_mobile_number"));
      })
      .addCase(RequestEditProfilephone.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
        toast.error(action?.payload?.message);
      });

    // update
    builder
      .addCase(RequestUpdateCustomer.pending, (state, action) => {
      })
      .addCase(RequestUpdateCustomer.fulfilled, (state, action) => {
        // state.customer = action.payload;
        toast.success(t('account-saved'));
      })
      .addCase(RequestUpdateCustomer.rejected, (state, action:any) => {
        toast.error(action?.payload?.phone[0]);
        toast.error(action?.payload?.message);
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setValueUser } = profileSlice.actions;

export default profileSlice.reducer;
