import React, { useEffect, useState } from "react";
import { Box, Grid, Stack } from "@mui/material";

import services1 from "../../assets/services/services-1.png";
import services3 from "../../assets/services/services-3.png";
import services2 from "../../assets/services/services-2.png";
import mask6 from "../../assets/services/mask-6.png";
import mask5 from "../../assets/services/mask-5.png";
import mask1 from "../../assets/services/mask-1.png";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import { CategoriesState } from "../../types/categories";
import { useLocation } from "react-router-dom";

const ServiceItem = ({ cate }: { cate: CategoriesState }) => {
  const [isHovering, setIsHovering] = useState(false);
  const lang = useSelector((state: RootState) => state.global.mainLang);

  // useEffect(()=>{
  //   onClick()
  // },[])
  return (
    <>
      <Box
        sx={{
          width: "100%",
          background: "#F2F2F2",
          overflow: "hidden",
          height: "148px",
          direction: "ltr",
          borderRadius: "8px",
        }}
        onMouseEnter={() => setIsHovering(true)}
        onMouseLeave={() => setIsHovering(false)}
      >
        <Grid
          container
          
          // direction="row"
          // sx={{
          //   width: "100%",
          //   position: "relative",
          //   alignItems: "center",
          //   height: "100%",
          // }}
        >
          <Grid
            item
            sm={2}
            md={2}
            lg={2}
            sx={{
              marginTop: "10px",
              width: "100%",
              textAlign: "center",
              fontSize: "18px",
              fontWeight: "600",
             
              
              // padding: "10px 32px",
              // flex: "1",
              // alignSelf: "center",
              // justifyContent: "start",
              // textAlign: "left",
              // fontSize: isHovering ? "22px" : "20px",
              // transform: isHovering ? "scale(1.1)": "scale(1)",
              // fontWeight: "600",
              // zIndex: "1",
              // transition: "font ease-out 300ms"
            }}
           
          >
            {cate.name}
          </Grid>
          <Grid
            item
            sm={5}
            md={5}
            lg={5}
            sx={{ width: "100%" }}
          >
            <img src={cate.cat_image} alt="services-1" width={"100%"} />
          </Grid>
          <Grid
            item
            sm={5}
            md={5}
            lg={5}
            sx={{
              // position: "absolute",
              // left: "0",
              // top: "0",
              width: "100%",
              // height: "100%",
            }}
          >
            <img
              src={cate.wall_image}
              alt="mask"
              style={{ width: "100%" ,height: "100%" }}
            />
          </Grid>
          
        </Grid>
      </Box>
    </>
  );
};

export default ServiceItem;
