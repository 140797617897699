import React, { useEffect, useState, useRef } from "react";
import { Box, Grid, Typography, Stack, Rating } from "@mui/material";
import { DarkButton, MainButton } from "../../style/style";
import StarIcon from "@mui/icons-material/Star";
import ReviewCard from "./reviewCard";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineInsertPhoto } from "react-icons/md";
import personImage from "../../assets/product/person.png";
import i18next, { t } from "i18next";
import {
  RequestGetReview,
  setIsAddReview,
} from "../../store/review/reviewSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { theme } from "../../utils/theme";
import styled from "@emotion/styled";
import { RequestPostReview } from "../../store/product/productSlice";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import { ReviewName } from "./reviewComment";
import { toast } from "react-toastify";
interface ReviwPostProps {
  display: boolean;
  productId: number;
}
const ReviewPost = ({ display, productId }: ReviwPostProps) => {
  const lang = useSelector((state: RootState) => state.global.mainLang);

  const isAddReview = useSelector(
    (state: RootState) => state.review.isAddReview
  );

  const [requestreview, setrequestreview] = useState({
    rating: 0,
    description: "",
    user: 1,
  });

  const StyledRating = styled(Rating)({
    "& .MuiRating-iconFilled": {
      color: "rgba(240, 194, 77, 1)",
    },
    "& .MuiRating-iconHover": {
      color: "rgba(240, 194, 77, 1)",
    },
  });
  const dispatch = useDispatch<AppDispatch>();
  const refImage = useRef<HTMLInputElement>(null);

  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const token = useSelector((state: RootState) => state.user.user);

  const postReview = () => {
    if (token) {
      if (requestreview.rating == 0) {
        toast.error(t("rate_done"));
        return false;
      }
      dispatch(RequestPostReview({ data: requestreview, id: productId })).then(
        (result) => {
          if (result.type === "RequestPostReview/fulfilled") {
            setrequestreview({
              rating: 0,
              description: "",
              user: 1,
            });
          }
        }
      );
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType("login"));
    }
  };
  useEffect(() => {}, [requestreview]);
  return (
    <Grid container  sx={{ display: display ? "flex" : "none"  }}>
      <Grid item sm={1} >
        <Stack sx={{ marginLeft: "15px" }}>
          <Box sx={{ display: "flex", alignItems: "center",justifyContent:"center"}}>
            <ReviewName>
              {userDetails?.first_name[0] ? userDetails?.first_name[0] : "G"}
            </ReviewName>
          </Box>
          <Box>
            <Typography sx={{ fontSize: "15px",textAlign:"center" }}>
              {userDetails?.first_name ? userDetails?.first_name : "Guest"}
            </Typography>
          </Box>
          {/* add Image to review*/}
          {/* <Typography component={"input"} sx={{display:"none"}} accept="image/*" type="file"  ref={refImage} onChange={(e:React.ChangeEvent<any>)=>setrequestreview((old)=>({...old,product_image:e.target.files[0]}))}></Typography>
        <Box onClick={()=>refImage?.current?.click()}>
          <MdOutlineInsertPhoto style={{ fontSize: "20px" }} />
        </Box> */}
        </Stack>
      </Grid>
      <Grid item sm={11}  >
        {/* <Box mt="16px">
        <StyledRating
          name="half-rating"
          defaultValue={0}
          precision={1}
          onChange={(e: any) =>
            setrequestreview((old) => ({ ...old, rating: e.target.value }))
          }
          value={requestreview?.rating}
          sx={{ direction: i18next.language === "ar" ? "ltr" : "ltr" }}
        />
      </Box> */}
        <Box
          sx={{
            marginTop: "19px",
            "&:placeholder": {
              color: "#8A8A8A",
              fontSize: "20px",
              fontWeight: "600",
            },
          }}
        ></Box>
        <textarea
          placeholder={t("Do you have a specific question?")}
          style={{
            width: "100%",
          
            border: "none",
            outline: "none",
            resize: "none",
          }}
          onChange={(e: any) =>
            setrequestreview((old) => ({ ...old, description: e.target.value }))
          }
          value={requestreview.description}
        />

        <Box
          sx={{
            width: "100%",
            height: "1px",
            background: "rgba(223, 223, 223, 1)",
            mt: "16px",
            mb: "16px",
          }}
        ></Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: { xs: "center", md: "start" },
            gap: "16px",
            flexWrap: "wrap",
          }}
        >
          {/* <DarkButton
          sx={{
            padding: "7px 0 !important",
            fontSize: "18px",
            width: { xs: "100%", md: "197px" },
          }}
          onClick={() => {
            setrequestreview({
              rating: 0,
              description: "",
              user: 1,
            });
            if (token) {
              dispatch(setIsAddReview(!isAddReview));
            } else {
              dispatch(setOpenLogin(true));
              dispatch(setSignType("login"));
            }
          }}
        >
          {t("cancel")}
        </DarkButton> */}
          <MainButton
            sx={{
              padding: "7px 0 !important",
              fontSize: "18px",
              paddingInline: "20px !important",
              // width: { xs: "20%", md: "20%" },
            }}
            onClick={() => postReview()}
          >
            {t("send")}
          </MainButton>
        </Box>
      </Grid>
    </Grid>
  );
};

export default ReviewPost;
