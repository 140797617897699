import React, {useEffect} from "react";
import Container from "@mui/material/Container/Container";
import MainSlider from "../components/home/MainSlider";
import RecommendSection from "../components/home/RecommendSection";
import OfferSection from "../components/home/offerSection";

import slider1 from "../assets/home/install-1.png";
import slider2 from "../assets/home/landing-1.png";
// import test2 from "../assets/home/test-2.png";
import VideoPerview from "../components/home/videoPerview";
import Footer from "../components/layout/footer";
import ServicesSection from "../components/home/servicesSection";
import SideSign from "../components/common/sideSign";
import {t} from "i18next";
import LikeYouSection from "../components/home/LikeYouSection";
import {useDispatch, useSelector} from "react-redux";
import {AppDispatch, RootState} from "../store/store";
import ComplainServices from "../components/home/complainServices";
import {RequestGetProfile} from "../store/profile/profileSlice";
import {RequestGetOffer, RequestGetUserOffer} from "../store/offer/offerSlice";
import HeadSlider from "../components/home/headSlider";
import Advertisement from "../components/home/advertisement";
import CategoryTwo from "../components/categoryone/categorytwo";
import CategoryThree from "../components/categoryone/categorythree";
import CategoryOne from "../components/categoryone/categoryone";


const label = {inputProps: {"aria-label": "Checkbox demo"}};
const Home = () => {
    const offers = useSelector((state: RootState) => state.offer.offer);
    const token = useSelector((state: RootState) => state.user.user)
    const dispatch = useDispatch<AppDispatch>();
    const landingInfo = [{image: slider2, alt: "slider 1"}, {image: slider1, alt: "slider 1"}, {
        image: slider2,
        alt: "slider 1"
    }, {image: slider1, alt: "slider 1"},];

    useEffect(() => {
        if (token) {
            // @ts-ignore
            dispatch(RequestGetUserOffer());
            dispatch(RequestGetProfile());
        } else {
            dispatch(RequestGetOffer());
        }
    }, []);


    return (
        <>
            <Advertisement/>
            <HeadSlider/>
            <SideSign/>
            <Container >
                {/* {------ads------------} */}
                {/* <MainSlider sliderInfo={landingInfo}/>  */}
                <CategoryOne/>
                  {/*-------فئات---------  */}
                <ServicesSection/>
                <CategoryTwo/>
                {offers?.count > 0 && <OfferSection/>}
            {/* {------------موصي به---------------} */}
                <RecommendSection
                    heading={t("Recommended for you")}
                    fetchUrl="/recommended/"
                />
                <CategoryThree/>
             {/* -------------ربما يعجبك---------------------- */}
                <LikeYouSection
                
                    heading={t("You may also like")}
                    fetchUrl="/likedproducts/"
                />

                <VideoPerview/>
            </Container>
            <Footer/>
            {token && <ComplainServices/>}

        </>);
};

export default Home;
