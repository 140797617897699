import React, { useEffect } from "react";
import { Box, Container, Grid, Typography } from "@mui/material";
import styled from "@emotion/styled";
import EmailSub from "../common/emailSub";
import googlePlay from "../../assets/home/google-play.png";
import appStore from "../../assets/home/app-store.png";
import FacebookOutlinedIcon from "@mui/icons-material/FacebookOutlined";
import InstagramIcon from "@mui/icons-material/Instagram";
import YouTubeIcon from "@mui/icons-material/YouTube";

import paymentImage from "../../assets/payment/payment-1.png";
import paymentImage2 from "../../assets/payment/payment-2.png";
import paymentImage3 from "../../assets/payment/payment-3.png";
import cyparta from "../../assets/payment/cypartal logo 1.png";
import { colors } from "../../utils/theme";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { CategoriesState } from "../../types/categories";
import { Link, useNavigate } from "react-router-dom";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import {
  RequestGetCategories,
  setBread,
  setFilter,
} from "../../store/products/productsSlice";
import { BiLogoFacebook } from 'react-icons/bi'

import { BsTiktok } from "react-icons/bs";
import { AiFillInstagram } from 'react-icons/ai'

export const NavItem = styled("li")(({ theme }) => ({
  color: "rgba(27, 27, 27, 1)",
  marginBottom: "16px",
  fontWeight: "500",
  fontSize: "16px",
  display: "flex",
  alignItems: "center",
  gap: "7px",
  "&:hover": {
    color: colors.blue[200],
  },
  cursor: "pointer",
}));
const NavbarNav = styled("ul")({
  listStyle: "none",
  padding: "0",
  margin: "24px 0"
});
const Footer = () => {
  const token = useSelector((state: RootState) => state.user.user);
  const userDetails = useSelector((state: RootState) => state.user.userDetails);
  const categories = useSelector((state: RootState) => state.products.cate);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();

  useEffect(() => {
    dispatch(RequestGetCategories());
  }, []);
  return (
    <Box
      sx={{
        pt: "48px",
        borderTop: "0.5px solid #CFCFCF",
        mt: "48px",
      }}
    >
      <Container >
        <Grid container spacing={3}>
          {/* <Grid item xs={12} sm={6} md={4} lg={2.5}> */}
            {/* <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Categories")}
            </Typography> */}
            {/* <NavbarNav>
              {categories
                .map((cate: CategoriesState, index: number) => {
                  return (
                    <NavItem
                      key={index}
                      onClick={() => {
                        navigate("/shop", { state: { category_id: cate?.id } });
                        dispatch(setBread(cate.name));
                        dispatch({
                          type: "products/setCategoryID",
                          payload: [cate.id],
                        });
                      }}
                    >
                      {cate.name}
                    </NavItem>
                  );
                })
                .slice(0, 3)}
            </NavbarNav> */}
          {/* </Grid> */}
          <Grid  item xs={12} sm={4}  lg={4}>
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Account_polices")}
            </Typography>
            <NavbarNav>
              <NavItem>
                {token ? (
                  <Link
                    to="/profile"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {t("Account")}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(setOpenLogin(true));
                      dispatch(setSignType("login"));
                    }}
                  >
                    {t("Account")}
                  </span>
                )}
              </NavItem>
              <NavItem>
                {token ? (
                  <Link
                    to="/profile/privacy"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {t("Privacy Policy")}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(setOpenLogin(true));
                      dispatch(setSignType("login"));
                    }}
                  >
                    {t("Privacy Policy")}
                  </span>
                )}
              </NavItem>
              <NavItem>
                {token ? (
                  <Link
                    to="/profile/order"
                    style={{ textDecoration: "none", color: "#000" }}
                  >
                    {t("Orders")}
                  </Link>
                ) : (
                  <span
                    onClick={() => {
                      dispatch(setOpenLogin(true));
                      dispatch(setSignType("login"));
                    }}
                  >
                    {t("Orders")}
                  </span>
                )}
              </NavItem>
            </NavbarNav>
          </Grid>
          <Grid item xs={12} sm={4} lg={4}>
            <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
              {t("Social Media")}
            </Typography>
            <NavbarNav>
              <Link
                to="https://www.facebook.com/people/Supersign-Belqas/pfbid035LWGsnguBqvFo7n6uiHvhCSkqfjstyiTngsXHR8CV8ZeUpTAFLqDpgQRrwKDZ2tRl/?mibextid=hIlR13"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  {/* <FacebookOutlinedIcon /> */}
                  <BiLogoFacebook style={{ fontSize: "21px" }} />
                  {t("Facebook")}
                </NavItem>
              </Link>
              <Link
                to="https://instagram.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  <AiFillInstagram style={{ fontSize: "21px" }} />
                  {t("Instagram")}
                </NavItem>
              </Link>
              <Link
                to="https://www.tiktok.com/"
                style={{ textDecoration: "none" }}
                target="_blank"
              >
                <NavItem>
                  {/* <YouTubeIcon /> */}
                  <BsTiktok style={{ fontSize: "21px" }} />
                  {t("tiktok")}
                </NavItem>
              </Link>
            </NavbarNav>
          </Grid>
          <Grid item xs={12} sm={4} lg={4} >
            <div style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              gap: "20px",
           
            }}>
              <img src={googlePlay} alt="google play" />
            </div>
          </Grid>
        </Grid>

        <Box
          sx={{
            mt: "35px",
            fontWeight: "400",
            pb: "32px",
            display: "flex",
            justifyContent: "space-between",
            flexDirection: { md: "row", xs: "column" },
            alignItems: "center",
            gap: { md: 0, xs: 2 },
          }}
        >
          <Box
            sx={{
              display: "flex",
              alignItems: "center",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <Typography
              sx={{
                height: "35px",
                display: "flex",
                alignItems: "center",
                pt: 1,
              }}
            >
              © {t("Copyright")}
            </Typography>
            <a href="https://cyparta.com/" target="_blank">
              <img src={cyparta} alt="cyparta" />
            </a>
          </Box>
          <Box>
            <span>{t("For inquiries")}</span>
            <a
              href="tel:+20106372764"
              style={{
                color: "rgba(14, 15, 29, 1)",
                fontWeight: "600",
                fontSize: "16px",
                textDecoration: "none",
              }}
            >
              <Typography
                component="span"
                sx={{
                  color: "rgba(14, 15, 29, 1)",
                  fontWeight: "600",
                  marginLeft: "10px",
                  marginRight: "10px",
                }}
              >
                {t('phone_en_ar')}
                {/* (+20) 1010628457 */}
              </Typography>
            </a>
          </Box>
        </Box>
      </Container>
    </Box>
  );
};

export default Footer;
