import {
  Box,
  Button,
  Container,
  FormControl,
  Grid,
  MenuItem,
  Select,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import Footer from "../components/layout/footer";
import ProductCard from "../components/common/productCard";
import Accordion from "../components/shop/accordion";
import AccordionContent from "../components/shop/accordionContent";
import AccordionCheck from "../components/shop/accordionCheck";
import BasicPagination from "../components/common/basicPagination";
import BreadCrumbs from "../components/common/breadCrumbs";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../store/store";
import {
  RequestGetProductsTest,
  setValuesFilter,
  setVideoReviews,
} from "../store/products/productsSlice";

import Skeleton from "@mui/material/Skeleton";
import { ProductProps } from "../types/product";
import { useTranslation } from "react-i18next";
import AccordionRate from "../components/shop/accordionRate";
import EmptyItems from "../components/common/emptyItems";
import { useLocation } from "react-router-dom";
import { CheckBoxCheck } from "../components/common/customCheck";
// import { useMediaQuery } from "@material-ui/core";
import useMediaQuery from "@mui/material/useMediaQuery";
import ShopFilterDrewer from "../components/common/shopFilterDrewer";
import FilterAltOutlinedIcon from "@mui/icons-material/FilterAltOutlined";
import { RequestGetColor } from "../store/color/colorSlice";

const Shop = () => {
  const [isChecked, setIsChecked] = useState<string>("all");
  const [open, setOpen] = useState<boolean>(false);
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const { t, i18n } = useTranslation();
  const isMobile = useMediaQuery("(max-width:900px)");
  const location = useLocation();
  const lang = useSelector((state: RootState) => state.global.mainLang);
  const [filterMobile, setFilterMobile] = useState(false);

  const dispatch = useDispatch<AppDispatch>();

  const { loading, cate, filter, products, error, breadCate } = useSelector(
    (state: RootState) => state.products
  );
  console.log("products", products);

  const { color } = useSelector((state: RootState) => state.color);
  const { results }: any = useSelector(
    (state: RootState) => state.products.products
  );

  const page = useSelector((state: any) => state.products.filter.page);

  const [selectResult, setSelectResult] = useState("Best seller");

  const Options = [
    { name: t("high_to_low"), value: "-price" },
    {
      name: t("low_to_high"),
      value: "price",
    },
    { name: t("newest_arrival"), value: "-created_at" },
  ];
  const crumbs = [
    { label: t("Home"), link: "/", active: false },
    {
      label: breadCate,
      link: "/shop",
      active: true,
    },
  ];

  useEffect(() => {
    dispatch(RequestGetColor());
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: "products/setCategoryID",
      payload: [parseInt(location.pathname.replace("/category/", ""))],
    });
  }, [location.pathname]);

  useEffect(() => {
    dispatch(
      RequestGetProductsTest({
        ...filter,
        category_ids: [parseInt(location.pathname.replace("/category/", ""))],
      })
    );
  }, [page]);

  const handleCloseDrawerFilter = () => {
    setFilterMobile(false);
  };

  return (
    <Box>
      <Container>
        <Box mt="48px" sx={{ px: { md: "0px" } }}>
          <BreadCrumbs crumbs={crumbs} />
        </Box>
        <Grid container mt="24px" spacing={3}>
          {!isMobile ? (
            <Grid
              item
              xs={0}
              sm={0}
              md={3}
              lg={3}
              xl={2}
              sx={{ display: { xs: "none", md: "block" } }}
            >
              <Box
                sx={{
                  background: "rgba(251, 251, 251, 1)",
                  height: "100%",
                  borderRadius: "10px",
                  padding: "0px 0",
                }}
              >
                <Accordion
                  text={t("price")}
                  content={<AccordionContent filter={filter} />}
                />
                <Box
                  sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
                ></Box>
                <Accordion
                  text={t("color")}
                  content={
                    <AccordionCheck
                      items={color}
                      filter={filter}
                      type="color_ids"
                    />
                  }
                />
                <Box
                  sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
                ></Box>

                <Accordion
                  text={t("rate")}
                  content={<AccordionRate type="avg_rating" filter={filter} />}
                />

                <Box
                  sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
                ></Box>

                <Accordion
                  text={t("categories")}
                  content={
                    <AccordionCheck
                      items={cate}
                      type="category_ids"
                      filter={filter}
                    />
                  }
                />
                <Box
                  sx={{ width: "100%", height: "1px", background: "#DFDFDF" }}
                ></Box>
              </Box>
            </Grid>
          ) : (
            <></>
          )}

          <Grid item xs={12} sm={12} md={9} lg={9} xl={10}>
            <Box>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "row",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Typography sx={{ fontWeight: "600", fontSize: "18px" }}>
                  {breadCate}
                </Typography>
                <Button
                  onClick={() => setFilterMobile(true)}
                  sx={{
                    display: {
                      md: "none",
                      xs: "block",
                      fontWeight: 500,
                      gap: 1,
                      fontSize: "18px",
                      color: "linear-gradient(90deg, #F5841F 0%, #ED1C24 100%)",
                    },
                  }}
                >
                  <FilterAltOutlinedIcon />
                  {t("filter")}
                </Button>
              </Box>

              <Stack
                direction="row"
                justifyContent={"space-between"}
                mt="12px"
                mb="45px"
              >
                <Typography>
                  {results?.length > 0 ? results?.length : 0}
                  {t("100 result")}
                </Typography>
                {/* filter.ordering */}
                <FormControl
                  sx={{
                    boxShadow: "none",
                    outline: "none",
                    border: "none",
                    ".MuiOutlinedInput-notchedOutline": { border: "none" },
                    "&.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                        outline: "none",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline":
                      {
                        border: "none",
                        outline: "none",
                      },
                    "&.MuiOutlinedInput-root.Mui-focused": {
                      border: "none",
                      outline: "none",
                    },
                    "&.MuiOutlinedInput-notchedOutline": {
                      border: "none",
                      outline: "none",
                    },
                    "& fieldset": {
                      border: "none",
                      outline: "none",
                    },
                    fontWeight: "500",
                  }}
                >
                  <Select
                    // variant="standard"
                    value={filter.ordering}
                    onChange={(e) => {
                      dispatch(
                        setValuesFilter({
                          type: "ordering",
                          val: e.target.value,
                        })
                      );
                    }}
                    sx={{
                      fieldset: { border: "none" },
                    }}
                    style={{
                      border: "none",
                      fontWeight: "600",
                      outline: "none",
                    }}
                    displayEmpty
                    inputProps={{ "aria-label": "Without label" }}
                  >
                    {Options.map((ele) => (
                      <MenuItem key={ele.value} value={ele.value}>
                        {ele.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Stack>
              {loading ? (
                <Grid container spacing={3}>
                  <>
                    {Array.from(new Array(12)).map((item) => {
                      return (
                        <Grid item xs={12} sm={6} md={3}>
                          <Skeleton
                            variant="rectangular"
                            width="100%"
                            height={250}
                            sx={{
                              textAlign: "center",
                              display: "block",
                              margin: "auto",
                            }}
                          />
                          <Skeleton variant="text" width="80%" />
                          <Skeleton variant="text" width="80%" />
                        </Grid>
                      );
                    })}
                  </>
                </Grid>
              ) : results?.length > 0 ? (
                <Grid container spacing={3}>
                  {results?.map((product: ProductProps) => {
                    return (
                      <Grid
                        item
                        xs={12}
                        sm={6}
                        md={6}
                        lg={4}
                        xl={3}
                        key={product.id}
                      >
                        <ProductCard type="new" product={product} />
                      </Grid>
                    );
                  })}
                </Grid>
              ) : (
                ""
              )}
              {results?.length === 0 && (
                <Grid item xs={12} md={12} sm={12}>
                  <EmptyItems loading={loading} />
                </Grid>
              )}
            </Box>
          </Grid>
        </Grid>
        <Box mt="40px" sx={{ direction: lang === "en" ? "ltr" : "rtl" }}>
          {products.count === 0 && (
            <Typography
              sx={{
                fontSize: "25px",
                fontWeight: "600 !important",
                color: "red",
                textAlign: "center",
              }}
            >
              {t("No product found")}
            </Typography>
          )}
          {products.count > 9 && (
            <BasicPagination products={products} filter={filter} />
          )}
        </Box>

        {/* <AlertResponse type={"error"} message={error} /> */}
      </Container>
      <ShopFilterDrewer open={filterMobile} onClose={handleCloseDrawerFilter} />
      <Footer />
    </Box>
  );
};

export default Shop;
