import { Box } from "@mui/material";
import React, {useEffect} from "react";
import Pagination from "@mui/material/Pagination";
import {
  RequestGetProductsTest,
  setPage,
} from "../../store/products/productsSlice";
import {useDispatch, useSelector} from "react-redux";
import { AppDispatch } from "../../store/store";
// import { useDispatch } from "react-redux";
// import { getMeat, setValues } from "../../store/shop/shopSlice";

const BasicPagination = ({
  products,
  filter,
}: {
  products?: any;
  filter: any;
}) => {
  const dispatch = useDispatch<AppDispatch>();


  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        mb: "50px",
        "& .MuiPaginationItem-root": {
          borderRadius: "4px",
          border: "1px solid #F5841F",
        },
        "& .Mui-selected": {
          borderBottom: "1px solid #000",
          background: "#fff !important",
          border: "1px solid #F5841F !important",
          color: "#F5841F !important",
        },
        "& .MuiPaginationItem-outlined": {
          color: "#000",
          border: "1px solid #DFE3E8",
          borderRadius:"4px !important"
        },
        "& .MuiPaginationItem-previousNext": {
          background: "#fff",
          color: "#000",
          borderRadius: "8px",
        },
        "& .MuiPaginationItem-previousNext.Mui-disabled": {
          background: "#919EAB",
          color:"#C4CDD5"
          
        },
      }}
    >
      <Pagination
        count={Math.ceil(products.count / parseInt(filter.limit))}
        page={Number(filter.page)}
        variant="outlined"
        
        onChange={(e, page) => {
          dispatch(setPage(page));
        }}
      />
    </Box>
  );
};

export default BasicPagination;
