import { Checkbox } from "@mui/material";
import React from "react";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

const CheckboxField = ({onChange, checked}: {onChange: any, checked: boolean}) => {
  return (
    <Checkbox
      {...label}
      checked={checked}
      icon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <g filter="url(#filter0_d_57_2523)">
            <path
              d="M15.625 2.5H4.375C3.33947 2.5 2.5 3.33947 2.5 4.375V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V4.375C17.5 3.33947 16.6605 2.5 15.625 2.5Z"
              fill="white"
            />
            <path
              d="M15.625 2.5H4.375C3.33947 2.5 2.5 3.33947 2.5 4.375V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V4.375C17.5 3.33947 16.6605 2.5 15.625 2.5Z"
              stroke="white"
              strokeWidth="1.5"
              stroke-linejoin="round"
            />
          </g>
          <defs>
            <filter
              id="filter0_d_57_2523"
              x="-6.25"
              y="-6.25"
              width="36.5"
              height="36.5"
              filterUnits="userSpaceOnUse"
              color-interpolation-filters="sRGB"
            >
              <feFlood flood-opacity="0" result="BackgroundImageFix" />
              <feColorMatrix
                in="SourceAlpha"
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                result="hardAlpha"
              />
              <feOffset dx="2" dy="2" />
              <feGaussianBlur stdDeviation="5" />
              <feComposite in2="hardAlpha" operator="out" />
              <feColorMatrix
                type="matrix"
                values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.08 0"
              />
              <feBlend
                mode="normal"
                in2="BackgroundImageFix"
                result="effect1_dropShadow_57_2523"
              />
              <feBlend
                mode="normal"
                in="SourceGraphic"
                in2="effect1_dropShadow_57_2523"
                result="shape"
              />
            </filter>
          </defs>
        </svg>
      }
      checkedIcon={
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M13.75 6.875L8.5 13.125L6.25 10.625"
            stroke="url(#paint0_linear_57_2525)"
            strokeWidth="1.5"
            strokeLinecap="round"
            stroke-linejoin="round"
          />
          <path
            d="M15.625 2.5H4.375C3.33947 2.5 2.5 3.33947 2.5 4.375V15.625C2.5 16.6605 3.33947 17.5 4.375 17.5H15.625C16.6605 17.5 17.5 16.6605 17.5 15.625V4.375C17.5 3.33947 16.6605 2.5 15.625 2.5Z"
            stroke="url(#paint1_linear_57_2525)"
            strokeWidth="1.5"
            stroke-linejoin="round"
          />
          <defs>
            <linearGradient
              id="paint0_linear_57_2525"
              x1="6.25"
              y1="9.93056"
              x2="13.7494"
              y2="9.99523"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5841F" />
              <stop offset="1" stopColor="#ED1C24" />
            </linearGradient>
            <linearGradient
              id="paint1_linear_57_2525"
              x1="2.5"
              y1="9.83333"
              x2="17.4992"
              y2="9.94112"
              gradientUnits="userSpaceOnUse"
            >
              <stop stopColor="#F5841F" />
              <stop offset="1" stopColor="#ED1C24" />
            </linearGradient>
          </defs>
        </svg>
      }
      onChange={onChange}
    />
  );
};

export default CheckboxField;
