import { Box, Container, Grid, Slider, Stack, Typography } from "@mui/material";
import React, { useState } from "react";
import { MainButton } from "../../style/style";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetProductsTest,
  setPriceRange,
} from "../../store/products/productsSlice";

function valuetext(value: number) {
  return `${value}°C`;
}

const AccordionContent = ({ filter }: { filter: any }) => {
  const [value, setValue] = React.useState<number[]>([0, 9000]);
  let dispatch = useDispatch<AppDispatch>();
  const handleChange = (event: Event, newValue: number | number[]) => {
    setValue(newValue as number[]);
  };
  const lang = useSelector((state: RootState) => state.global.mainLang);

  return (
    <Box>
      <Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
            gap: "20px",
          }}
        >
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              order: lang === "en" ? 1 : 0,
              background: "#fff",
              padding: "5px",
              borderRadius: "6px",
            }}
          >
            <input
              style={{
                border: "none",
                width: "50px",
                background: "transparent",
                textAlign: "center",
              }}
              className="input_focused"
              value={value[1]}
              type={"number"}
              onChange={(e: any) => handleChange(e, [value[0], e.target.value])}
            />
          </Typography>
          <Typography
            sx={{
              fontSize: "14px",
              fontWeight: "300",
              background: "#fff",
              padding: "5px",
              textAlign: "center",
              borderRadius: "6px",
            }}
          >
            <input
              style={{
                width: "50px",
                background: "transparent",
                textAlign: "center",
                border:"none"
              }}
              className="input_focused"

              value={value[0]}
              type={"number"}
              onChange={(e: any) => handleChange(e, [e.target.value, value[1]])}
            />
          </Typography>
        </Box>

        <Box mt="30px">
          <Slider
            getAriaLabel={() => "Temperature range"}
            value={value}
            onChange={handleChange}
            valueLabelDisplay="auto"
            getAriaValueText={valuetext}
            step={10}
            min={0}
            max={9000}
          />
        </Box>

        <MainButton
          sx={{ padding: "5px !important", mt: "20px", mb:"20px" }}
          onClick={() => {
            dispatch(
              RequestGetProductsTest({
                ...filter,
              })
            );
            dispatch(setPriceRange({ lte: value[1], gte: value[0] }));
          }}
        >
          {t("apply")}
        </MainButton>
      </Box>
    </Box>
  );
};

export default AccordionContent;
