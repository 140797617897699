import { Box, Typography } from "@mui/material";
import { Link } from "react-router-dom";
import React from "react";
import i18next from "i18next";

import arrow from "../../assets/home/arrowRight.svg";
import KeyboardArrowLeftIcon from "@mui/icons-material/KeyboardArrowLeft";
interface BreadCrumbsProps {
  label: string;
  link: string;
  active: boolean;
  onClick?: any;
}

const BreadCrumbs = ({ crumbs = [] }: any) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          flexDirection: "row",
          gap: "4px",
        }}
      >
        {crumbs?.map((crumb: any, index: any) => (
          <React.Fragment key={index}>
            <Typography
              component="span"
              onClick={(e) => {
                e.preventDefault();

                if (typeof crumb.onClick === "function") {
                  crumb.onClick();
                }
              }}
            >
              <Link
                to={crumb.link}
                style={{
                  color: crumb.active ? "#9A9A9A" : "#1B1B1B",
                  fontWeight: "600",
                  opacity: crumb.active && "0.5",
                  textDecoration: "none",
                }}
              >
                {crumb.label}
              </Link>
            </Typography>
            <Typography
              mx="8px"
              sx={{
                opacity: crumb.active && "0.5",
                direction: i18next.language === "en" ? "ltr" : "rtl",
              }}
              component="span"
            >
              {!crumb.active && i18next.language === "en" ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="8"
                  height="14"
                  viewBox="0 0 8 14"
                  fill="none"
                >
                  <path
                    d="M1.0715 12.1426L6.21436 6.99979L1.0715 1.85693"
                    stroke="#1B1B1B"
                    strokeWidth="2"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                  />
                </svg>
              ) : (
                !crumb.active && <KeyboardArrowLeftIcon />
              )}
            </Typography>
          </React.Fragment>
        ))}
      </Box>
    </>
  );
};

export default BreadCrumbs;
