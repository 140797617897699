import * as React from "react";
import Box from "@mui/material/Box";
import Drawer from "@mui/material/Drawer";
import Button from "@mui/material/Button";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import InboxIcon from "@mui/icons-material/MoveToInbox";
import MailIcon from "@mui/icons-material/Mail";
import { Typography, OutlinedInput } from "@mui/material";
import PasswordField from "./passwordField";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import { MainButton } from "../../style/style";
import Login from "../sign/login";
import Sign from "../sign/sign";
import ForgotPassword from "../sign/forgotPassword";
import VerfiyMobile from "../sign/verifyMobile";
import Reset from "../sign/reset";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";
import useMediaQuery from "@mui/material/useMediaQuery";
import VerfiyMobileEdit from "../sign/verifyMobileEdit";
import VerfiyForgetPassword from "../sign/verfiyForgetPassword";
import ActiveMobile from "../sign/activePassword";

type Anchor = "top" | "left" | "bottom" | "right";

export default function SideSign({ open, onClose }: any) {
  const [state, setState] = React.useState({
    right: false,
    left: false,
  });

  const type = useSelector((state: RootState) => state.global.signType);
  const lang = useSelector((state: RootState) => state.global.mainLang);

  const matches = useMediaQuery("(max-width:600px)");
  const isFull = useMediaQuery("(max-width:430px)");
  // const [type, setType] = React.useState("login");

  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === "keydown" &&
        ((event as React.KeyboardEvent).key === "Tab" ||
          (event as React.KeyboardEvent).key === "Shift")
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{
        width: matches ? "100vw" : "500px",
        padding: "32px",
        direction: lang === "en" ? "ltr" : "rtl",
        height: "100%",
      }}
      role="presentation"
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Box sx={{ height: "100%" }}>
        {type === "login" ? (
          <Login />
        ) : type === "forgot" ? (
          <ForgotPassword />
        ) : type === "activeMobile" ? (
          <ActiveMobile />
        ) : type === "verfiy" ? (
          <VerfiyMobile />
        ) : type === "verfiyForget" ? (
          <VerfiyForgetPassword />
        ) : type === "reset" ? (
          <Reset />
        ) : type === "verfiyEdit" ? (
          <VerfiyMobileEdit />
        ) : type === "sign" ? (
          <Sign />
        ) : (
          ""
        )}
      </Box>
    </Box>
  );

  return (
    <div>
      <React.Fragment key={lang === "en" ? "right" : "left"}>
        <Drawer
          anchor={lang === "en" ? "right" : "right"}
          open={open}
          onClose={onClose}
          disableScrollLock={true}
          sx={{
            "&.MuiModal-root": {
              zIndex: isFull ? "12" : "11",
              top: "80px",
            },
            "& .MuiDrawer-paper": {
              // top: isFull ? "0px" : "109px",
              paddingTop: isFull ? "0px" : "115px",
            },
            "& .MuiModal-backdrop": {
              background: "rgba(27, 27, 27, 0.10) !important",
            },
          }}
        >
          {list(lang === "en" ? "right" : "left")}
        </Drawer>
      </React.Fragment>
    </div>
  );
}
