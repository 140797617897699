import React from "react";
import { Box } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import CustomCheck from "../common/customCheck";
import Rating from "@mui/material/Rating";
import { filterData } from "../../store/products/productsSlice";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface AccordionRateState {
  items?: any;
  type?: string;
  filter?: filterData;
}


const AccordionRate = ({ items, type, filter }: AccordionRateState) => {
  return (
    <Box sx={{mb:"20px"}}>
      {[1, 2, 3, 4, 5]?.map((item: any) => {
        return (
          <CustomCheck
            sx={{ span: { lineHeight: "0 !important" } }}
            id={item}
            name={
              <Box style={{ lineHeight: '0' }}>
                <Rating
                  name="half-rating"
                  defaultValue={item}
                  precision={0.5}
                  readOnly
                  sx={{ lineHeight: '0', fontSize:"20px", color:"rgba(240, 194, 77, 1)" }}
//                   icon={<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 28 26" fill="none">
//                   <path d="M13.3054 0.717387C13.6167 -0.178173 14.8833 -0.178173 15.1946 0.717387L17.7834 8.16501C17.9207 8.56008 18.2894 8.82795 18.7075 8.83647L26.5906 8.99711C27.5386 9.01643 27.9299 10.221 27.1744 10.7938L20.8913 15.5573C20.558 15.81 20.4171 16.2434 20.5383 16.6438L22.8215 24.1907C23.096 25.0982 22.0714 25.8426 21.2932 25.3011L14.8212 20.7975C14.4779 20.5586 14.0221 20.5586 13.6788 20.7975L7.20684 25.3011C6.4286 25.8426 5.40396 25.0982 5.67851 24.1907L7.96173 16.6438C8.08285 16.2434 7.94202 15.81 7.60873 15.5573L1.3256 10.7938C0.570066 10.221 0.961446 9.01643 1.90937 8.99711L9.79246 8.83647C10.2106 8.82795 10.5793 8.56008 10.7166 8.16501L13.3054 0.717387Z" fill="#F0C24D"/>
//                   </svg>}
//                   emptyIcon={
//                     <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 28 26" fill="none">
// <path opacity="0.3" d="M13.3054 0.717387C13.6167 -0.178173 14.8833 -0.178173 15.1946 0.717387L17.7834 8.16501C17.9207 8.56008 18.2894 8.82795 18.7075 8.83647L26.5906 8.99711C27.5386 9.01643 27.9299 10.221 27.1744 10.7938L20.8913 15.5573C20.558 15.81 20.4171 16.2434 20.5383 16.6438L22.8215 24.1907C23.096 25.0982 22.0714 25.8426 21.2932 25.3011L14.8212 20.7975C14.4779 20.5586 14.0221 20.5586 13.6788 20.7975L7.20684 25.3011C6.4286 25.8426 5.40396 25.0982 5.67851 24.1907L7.96173 16.6438C8.08285 16.2434 7.94202 15.81 7.60873 15.5573L1.3256 10.7938C0.570066 10.221 0.961446 9.01643 1.90937 8.99711L9.79246 8.83647C10.2106 8.82795 10.5793 8.56008 10.7166 8.16501L13.3054 0.717387Z" fill="#F0C24D"/>
// </svg>
//                   }
                />
              </Box>
            }
            key={item}
            filter={filter}
            type={type}
          />
        );
      })}
    </Box>
  );
};

export default AccordionRate;
