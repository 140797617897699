import React from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import PasswordField from "../common/passwordField";
import PhoneField from "../common/phoneField";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { colors } from "../../utils/theme";
import SocialSection from "./socialSection";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestPostLogin, RequestSendOtp, setErrorMsg } from "../../store/user/user";
import ControlField from "../common/controlField";
import Cookies from "js-cookie";
import { t } from "i18next";
import TestPhone from "../common/TestPhone";
import Checkbox from "@mui/material/Checkbox";
import { CheckBoxCheck } from "../common/customCheck";
import CheckboxField from "../common/checkbox/checkbox";
const label = { inputProps: { "aria-label": "Checkbox demo" } };
const Login = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMsg, errorLogin } = useSelector(
    (state: RootState) => state.user
  );
  const lang = useSelector((state: RootState) => state.global.mainLang);

  const [checked, setChecked] = React.useState(true);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setChecked(event.target.checked);
  };

  const formik = useFormik({
    initialValues: {
      password: "",
      phone: "",
    },
    onSubmit: (values, { setSubmitting }) => {
      const { password, phone } = values;
      dispatch(RequestPostLogin({ password, phone })).then((req) => {
        {
          if (req.type == "RequestPostLogin/fulfilled") {
            if (checked) {
              Cookies.set("token", req.payload.token);
              localStorage.setItem("userDetails", JSON.stringify(req?.payload));
            }
            dispatch(setErrorMsg(""));
            dispatch(setOpenLogin(false));
          }
          if (req.type == "RequestPostLogin/rejected") {
            if (
              req?.payload?.message == "This account not verified."
            ) {
              dispatch(setSignType("activeMobile"));
            }
          }
        }
      });
    },
  });
  return (
    <Box
      sx={{
        // display: "grid",
        flexDirection: "column",
        height: "100%",
        direction: lang == "en" ? "ltr" : "rtl",
        display: "flex",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
          {t("login")}
        </Typography>
        <Typography
          sx={{
            color: "#505050",
            mt: "8px",
            fontWeight: "400",
            opacity: "0.5",
          }}
        >
          {t("welcome_back")}
        </Typography>

        {/* <SocialSection /> */}
        {/* <Box mt="15px">

        <TestPhone />
        </Box> */}
        <form onSubmit={formik.handleSubmit}>
          <Box mt="32px">
            <PhoneField
              label={t("mobile_number")}
              id="phone"
              name="phone"
              value={formik.values.phone}
              onChange={(e: any) => {
                formik.setFieldValue("phone", e);
              }}
            />
          </Box>

          <Box mt="20px">
            <PasswordField
              name="password"
              label={t("password")}
              onChange={formik.handleChange}
              value={formik.values.password}
            />
            <Typography sx={{ color: "red", mt: "10px" }}>
              {errorLogin && errorLogin}
            </Typography>
          </Box>

          <button type="submit" style={{ display: "none" }}>
            test
          </button>
        </form>

        <Box sx={{ display: "flex", justifyContent: "end", mt: "12px" }}>
          <Typography
            sx={{ fontWeight: "300", fontSize: "14px", cursor: "pointer" }}
            onClick={() => dispatch(setSignType("forgot"))}
          >
            {t("forgot_password")}
          </Typography>
        </Box>

        <Box>
          {/* for remember me */}
          {/* <input 
            type="checkbox"
            // onChange={() => setChecked()}
          /> */}
          {/* rgba(245, 132, 31, 1) */}

          {/* <Checkbox
            checked={checked}
            onChange={handleChange}
            inputProps={{ "aria-label": "controlled" }}
            sx={{
              color: 'rgba(245, 132, 31, 1)',
              '&.Mui-checked': {
                color: 'rgba(245, 132, 31, 1)',
              },
            }}
          />
            {t("remember_me")} */}

          <Box sx={{ display: "flex", gap: "0px", alignItems:"center" }}>
            <CheckboxField onChange={(e:any) => setChecked(e.target.checked)} checked={checked}/>
            {t("remember_me")}
          </Box>
        </Box>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => formik.handleSubmit()}
        >
          {loading ? t("loading") : t("signin")}
        </MainButton>
        <Typography
          mt="24px"
          sx={{
            textAlign: "center",
            color: "rgba(0, 0, 0, 0.40) !important",
            fontWeight: "400",
          }}
          onClick={() => dispatch(setSignType("sign"))}
        >
          {t("first_time_here")} {""}
          <span style={{ color: colors.orange[100], cursor: "pointer" }}>
            {t("sign_up")}
          </span>
        </Typography>
      </Box>
    </Box>
  );
};

export default Login;
