import React, { useState, useEffect } from "react";
import { Box, Grid, Typography, Stack, Button, Skeleton } from "@mui/material";
import { DarkButton, MainButton } from "../../style/style";
import StarIcon from "@mui/icons-material/Star";
import ReviewCard from "./reviewCard";
import { AiFillStar } from "react-icons/ai";
import { MdOutlineInsertPhoto } from "react-icons/md";
import personImage from "../../assets/product/person.png";
import ReviewPost from "./reviewPost";
import ReviewComment from "./reviewComment";
import { t } from "i18next";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import { setIsAddReview } from "../../store/review/reviewSlice";

const ReviewSection = ({ reviews, product }: any) => {
  const [review, setReview] = useState(false);
  const isAddReview = useSelector((state: RootState) => state.review.isAddReview);
  const [reviewCount, setReviewCount] = useState(2);
  const token = useSelector((state: RootState) => state.user.user);
  let dispatch = useDispatch<AppDispatch>();
  function addReview() {
    if (token) {
      dispatch(setIsAddReview(!isAddReview))
      // setReview(!review);
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType('login'))
    }
  }
  function increaseReview() {
    if (reviewCount >= reviews?.length) {
      setReviewCount(2);
    } else {
      setReviewCount(reviewCount + 2);
    }
  }
  useEffect(() => {}, [reviewCount]);
  return (
    <Box
      mt="48px"
      sx={{
        boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.08)",
        padding: "25px",
        borderRadius: "4px",
      }}
    >
      <Grid container spacing={3}>
        {/* <Grid item xs={12} md={4}> */}
          {/* <Box mt="50px">
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <StarIcon
                style={{
                  color: "rgba(240, 194, 77, 1)",
                  fontSize: "32px",
                  marginRight: "8px",
                }}
              />
              <Typography
                sx={{
                  fontFamily: "Inter, sans-serif !important",
                  fontSize: "64px",
                }}
              >
                {product?.avg_rating?.toFixed(2)} 
              </Typography>
            </Box>
            <Typography
              sx={{
                fontSize: "12px",
                fontFamily: "Inter, sans-serif !important",
                fontWeight: "600",
                textAlign: "center",
              }}
            >
             {product?.total_ratings_count} {t("ratings")}
            </Typography>
            {!product?.user_has_reviewed && 
              <Box sx={{ textAlign: "center", mt: "32px" }}>
              <button
                className="main-outline-btn main-text review-button"
                onClick={addReview}
              >
                {t("Add Review")}
              </button>
            </Box>
            }
            
          </Box> */}
        {/* </Grid> */}
        <Grid item xs={12} md={12}>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                mb: "16px",
              }}
            >
              {/* <Typography sx={{ color: "#505050", fontSize: "12px" }}>
                {reviews?.length} {t("comments")}
              </Typography> */}
              {/* 
              <Typography sx={{ color: "#1B1B1B", fontSize: "16px" }}>
                Last month
              </Typography> 
              */}
            </Box>

            <ReviewPost
              display={isAddReview || reviews?.length == 0}
              productId={product?.id}
            />
            
            {/* <Box>
              {reviews?.map((review: any, index: number) => {
                if (index < reviewCount) {
                  return (
                    <ReviewComment
                      review={review}
                      key={review?.id}
                      length={reviewCount}
                      index={index}
                    />
                  );
                }
              })}
            </Box> */}
            <Button
              onClick={increaseReview}
              sx={{ display: reviews?.length > reviewCount ? "block" : "none" }}
            >
              {t("seemore")}
            </Button>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default ReviewSection;
