import React from "react";
import { Box } from "@mui/material";
import "../../style/checkbox.css";
import CheckIcon from "@mui/icons-material/Check";
import CategoryCard from "../home/categoryCard";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetProductsTest,
  setFilter,
  setVideoReviews,
} from "../../store/products/productsSlice";

import styled from "@emotion/styled";

export const CheckBoxCheck = styled(Box)({
  width: "20px !important",
  height: "20px !important",
  background: "#fff",
  boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.08)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  padding: "0 !important",
  "&::before": {
    borderRadius: "0px",
    padding: "2px",
  },
  flex: "0 0 22px",
  maxWidth: "22px",
});

const CustomCheck = ({ id, name, type }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [isChecked, setIsChecked] = React.useState(false);
  const { loading, cate, filter } = useSelector(
    (state: RootState) => state.products
    );
  return (
    <>
      <Box
        sx={{
          display: "flex",
          gap: "10px",
          alignItems: "center",
          padding: "0 0px",
          mb: "8px",
          cursor:"pointer"
        }}
        onClick={() => {
          setIsChecked(!isChecked);

          if (type == "video_reviews") {
            dispatch(setVideoReviews(isChecked));
            return;
          }
          if (!filter[type].includes(id)) {
            dispatch({
              type: "products/setFilter",
              payload: { type: type, val: id },
            });
            dispatch(RequestGetProductsTest({ [type]: id }));
          } else {
            dispatch({
              type: "products/setFilterRemove",
              payload: { type: type, val: id },
            });
            dispatch(RequestGetProductsTest({ [type]: id }));
          }
        }}
      >
        <CheckBoxCheck className={`${filter[type].includes(id) && "container main-outline-btn"}`}>
          {filter[type].includes(id) && (
            <span className="main-text">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="10"
                height="8"
                viewBox="0 0 10 8"
                fill="none"
              >
                <path
                  d="M8.75 0.875L3.5 7.125L1.25 4.625"
                  stroke="url(#paint0_linear_57_2520)"
                  strokeWidth="1.5"
                  strokeLinecap="round"
                  strokeLinejoin="round"
                />
                <defs>
                  <linearGradient
                    id="paint0_linear_57_2520"
                    x1="1.25"
                    y1="3.93056"
                    x2="8.74944"
                    y2="3.99523"
                    gradientUnits="userSpaceOnUse"
                  >
                    <stop stopColor="#F5841F" />
                    <stop offset="1" stopColor="#ED1C24" />
                  </linearGradient>
                </defs>
              </svg>
            </span>
          )}
        </CheckBoxCheck>
        <span>{name}</span>
      </Box>
    </>
  );
};

export default CustomCheck;
