import Slider from "react-slick";
import slide1 from "../../assets/slider/1.webp";
import slide2 from "../../assets/slider/2.webp";
import slide3 from "../../assets/slider/3.webp";
import slide4 from "../../assets/slider/4.webp";
import { useEffect } from "react";
import { RequestGetAds } from "../../store/ads/adsSlice";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { Box, Skeleton } from "@mui/material";

const HeadSlider = () => {
  const { ads, loading } = useSelector((state: RootState) => state.ads);
  console.log("ads", ads);

  const dispatch = useDispatch<AppDispatch>();
  const settings = {
    dots: true,
    infinite: true,
    speed: 5000,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 0,
    autoplay: true,
    pauseOnHover: false,
    once: false,
    adaptiveHeight: true,
    appendDots: (dots:any) => <ul>{dots}</ul>,
  };
  useEffect(() => {
    dispatch(RequestGetAds());
  }, []);
  return (
    <div className="slider-container">
      <Slider {...settings}>
        {loading ? (
          <Box sx={{ height: "288px", width: "100%" }}>
            <Skeleton variant="rectangular" width={"100%"} height={"100%"} />
          </Box>
        ) : (
          ads.map((photo: { image_ad: string; name: string }, index: any) => {
            return (
              <Box
                key={index}
                sx={{ height: { md: "288px", xs: "100%" }, width: "100%" }}
              >
                <img
                  src={photo.image_ad}
                  alt={photo.name}
                  style={{ width: "100%", height: "100%" }}
                  key={index}
                />
              </Box>
            );
          })
        )}

        {/* <div>
        <img src={slide2} width={"100%"} height={"100%"} />
      </div>
      <div>
        <img src={slide3} width={"100%"} height={"100%"} />
      </div>
      <div>
        <img src={slide4} width={"100%"} height={"100%"} />
      </div>
       */}
      </Slider>
    </div>
  );
};
export default HeadSlider;
