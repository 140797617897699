import React, { useEffect } from "react";
import { Box, Grid, Stack, Skeleton } from "@mui/material";
import services1 from "../../assets/services/services-1.png";
import services2 from "../../assets/services/services-2.png";
import services3 from "../../assets/services/services-3.png";
import mask5 from "../../assets/services/mask-5.png";
import mask6 from "../../assets/services/mask-6.png";
import ServiceItem from "./serviceItem";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import {
  RequestGetCategories,
  setBread,
  setFilter,
} from "../../store/products/productsSlice";
import { CategoriesState } from "../../types/categories";
import { useNavigate } from "react-router-dom";
import Slider from "react-slick";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { t } from "i18next";


const sliderItemStyle = {
  marginRight: "10px", // Adjust the margin to control the space
};

const ServicesSection = () => {
  // const test = Array.from
  const categories: CategoriesState[] = useSelector(
    (state: RootState) => state.products.cate
  );
  console.log("categories", categories);
  
  const cateLoading = useSelector(
    (state: RootState) => state.products.cateLoading
  );
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  useEffect(() => {
    dispatch(RequestGetCategories());
  }, []);

  var settings = {
    className: "serv",
    speed: 2000,
    slidesToShow: 3,
    slidesToScroll: 1,
    // initialSlide: 0,
    autoplay: true,
    infinite: true,
    responsive: [
      {
        breakpoint: 1400,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 750,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <Box mt="48px">
      <h2>{t("Category")}</h2>
      {cateLoading ? (
        <Slider {...settings}>
          {Array.from(new Array(3)).map((item, index) => {
            return (
              <Box key={index}>
                <Skeleton
                  variant="rectangular"
                  width="100%"
                  height={150}
                  sx={{
                    textAlign: "center",
                    display: "block",
                    margin: "auto",
                  }}
                />
              </Box>
            );
          })}
        </Slider>
      ) : (
        <Slider {...settings}>
          {categories?.map((cate: CategoriesState) => {
            return (
              <Box
                key={cate.id}
                onClick={() => {
                  navigate(`/category/${cate?.id}`, { state: { category_id: cate?.id } });
                  dispatch(setBread(cate.name));
                  // dispatch({
                  //   type: "products/setCategoryID",
                  //   payload: [cate.id],
                  // });
                }}
                sx={{ cursor: "pointer", borderRadius: "8px" }}
              >
                <ServiceItem cate={cate} />
              </Box>
            );
          })}
        </Slider>
      )}

      {/* <Grid container spacing={2}>
        {categories?.map((cate: CategoriesState) => {
          return (
            <Grid
              item
              md={4}
              onClick={() => {
                navigate("/shop", { state: { category_id: cate?.id } });
                dispatch(setBread(cate.name));
                dispatch({
                  type: "products/setCategoryID",
                  payload: [cate.id],
                });
              }}
            >
              <ServiceItem cate={cate} />
            </Grid>
          );
        })}
      </Grid> */}
    </Box>
  );
};

export default ServicesSection;
