import React, { useEffect, useRef, useState } from "react";
import { Box, Container, Grid, Typography, styled, Stack } from "@mui/material";
import imageProduct from "../assets/product/product-1.png";
import imageProduct2 from "../assets/product/product-2.png";
import imageProduct3 from "../assets/product/product-3.png";
import imageProduct4 from "../assets/product/product-4.png";
import StarIcon from "@mui/icons-material/Star";
import { colors } from "../../utils/theme";
import { IconBox, MainButton } from "../../style/style";
import BasicTable from "../../components/productDetails/table";
import RecommendSection from "../home/LikeYouSection";
import ReviewSection from "../../components/productDetails/reviewSection";
import { BiSolidDownArrow, BiSolidUpArrow } from "react-icons/bi";
import IosShareIcon from "@mui/icons-material/IosShare";
import OfferDetails from "../../components/productDetails/offerDetails";
import { savedIcon, transferIcon, uploadIcon } from "../../assets/svg/svg";
import { NavbarNav } from "../../pages/productDetails";
import { ProductProps } from "../../types/product";
import { t } from "i18next";
import Rating from "@mui/material/Rating";
import useCustomHooks from "../../hooks/customHooks";
import { BsBookmark } from "react-icons/bs";
import useAddToCart from "../../hooks/useAddToCart";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { setOpenLogin, setSignType } from "../../store/globalSlice";
import useCompare from "../../hooks/useCompare";
import { BiTransfer } from "react-icons/bi";
import { toast } from "react-toastify";
import { FiMinus } from "react-icons/fi";
import { LuPlus } from "react-icons/lu";

const StyledRating = styled(Rating)({
  "& .MuiRating-iconFilled": {
    color: "rgba(240, 194, 77, 1)",
  },
  "& .MuiRating-iconHover": {
    color: "rgba(240, 194, 77, 1)",
  },
});

const ProductsInfo = ({ product }: any) => {
  const dispatch = useDispatch<AppDispatch>();
  const [quantity, setQuantity] = useState<number>(1);
  const [totalPrice, setTotalPrice] = useState<number>(
    parseFloat(product.price)
  );
  const token = useSelector((state: RootState) => state.user.user);
  const handleCompare = useCompare();
  const handleAddItem = useAddToCart();
  const handleConsole = useCustomHooks();

  const handleSaveItem = () => {
    if (token) {
      handleConsole(product?.id, product?.is_wishlist);
    } else {
      dispatch(setOpenLogin(true));
      dispatch(setSignType("login"));
    }
  };

  const linkRef = useRef<any>(null);
  const [copied, setCopied] = useState(false);
  const copyToClipboard = () => {
    const el = document.createElement("input");
    el.value = window.location.href;
    document.body.appendChild(el);
    el.select();
    document.execCommand("copy");
    document.body.removeChild(el);
    setCopied(true);
    toast.success(t("copied_done"));
  };

  const [isHoveringSaved, setIsHoveringSaved] = useState(false);
  const [isHoveringTransfer, setIsHoveringTransfer] = useState(false);
  const [isHoveringCopied, setIsHoveringCopied] = useState(false);

  useEffect(() => {
    setTotalPrice(quantity * parseFloat(product.price));
  }, [quantity, product.price]);

  return (
    <Stack direction="row">
      <Box sx={{ flex: 1 }}>
        <Box>
          <Typography
            component={"h1"}
            sx={{
              fontSize: "2rem",
              fontWeight: "600",
              lineHeight: "29.26px",
              maxWidth: "607px",
              mb: "16px",
            }}
          >
            {product?.name}
          </Typography>
        </Box>
        <Box sx={{ display: "flex", alignItems: "center", mb: "24px" }}>
          {/* <Typography
            sx={{
              color: colors.blue[100],
              fontWeight: "600",
              fontSize: "28px",
            }}
            component={"span"}
          >
            {product?.price} {t("egp")}
          </Typography> */}
        </Box>

        <Box sx={{ fontSize: "18px", mb: "32px" }}>
          <Typography sx={{ fontSize: "18px", fontWeight: "600" }}></Typography>

          <NavbarNav>
            {product?.product_description?.map((ele: any, index: number) => {
              if (ele.about_item.length > 0) {
                return (
                  <li key={index} style={{ color: ele.color }}>
                    {ele.about_item}
                  </li>
                );
              } else {
                return null; // Return null instead of an empty <p> tag
              }
            })}
          </NavbarNav>
        </Box>

        <Box
          mb="40px"
          sx={{
            padding: "10px",
            textAlign: "center",
            marginTop: "50px",
            paddingBottom: "20px",
            border: "1px solid #dcdcdc",
            width: { lg: "50%", md: "75%", xs: "100%", sm: "100%" },
            borderRadius: "3px",
          }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <p style={{ fontSize: "18px", fontWeight: "500" }}>
              {t("Quantity")}
            </p>
            <Box
              sx={{
                marginRight: "50px",
                paddingRight: "50px",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                border: "0.5px solid #dcdcdc",
                borderRadius: "3px",
                color: "#54556b",
                paddingInline: "20px",
              }}
            >
              <LuPlus
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "#54556b",
                  fontWeight: "700",
                }}
                onClick={() => {
                  if (quantity >= product.quantity) {
                    toast.error(t("quantity-enough"));
                    return false;
                  }
                  setQuantity((prevQuantity) => prevQuantity + 1);
                }}
              />
              <input
                type="number"
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  textAlign: "center",
                  border: "none",
                  borderRight: "0.5px solid #dcdcdc",
                  borderLeft: "0.5px solid #dcdcdc",
                  marginInline: "20px",
                  outline: "none",
                  width: "60px",
                  fontSize: "18px",
                  padding: "10px",
                  color: "#54556b",
                  fontWeight: "700",
                }}
                className="input-number"
                placeholder="1"
                value={quantity}
                onChange={(e: any) => {
                  const newQuantity = parseInt(e.target.value);
                  if (newQuantity <= 0) return;
                  setQuantity(newQuantity);
                }}
              />

              <FiMinus
                style={{
                  fontSize: "18px",
                  cursor: "pointer",
                  color: "#54556b",
                  fontWeight: "700",
                }}
                onClick={() => {
                  if (quantity > 1) {
                    setQuantity((prevQuantity) => prevQuantity - 1);
                  }
                }}
              />
            </Box>
          </div>
          <div style={{ display: "flex", alignItems: "center" }}>
            <p
              style={{
                display: "flex",
                alignItems: "center",
                fontSize: "18px",
                fontWeight: "500",
              }}
            >
              {t("price")}{" "}
              <span
                style={{
                  marginRight: "50px",
                  paddingRight: "50px",
                  fontWeight: "400",
                  color: "red",
                  fontSize: "20px",
                }}
              >
                {totalPrice} {t("egp")}
              </span>
            </p>
            {product?.old_price > 0 && (
              <Typography
                component={"span"}
                sx={{
                  textDecoration: "line-through",
                  fontWeight: "400",
                  fontSize: "18px",
                  ml: "10px",
                  mr: "10px",
                }}
              >
                {product?.old_price} {t("egp")}
              </Typography>
            )}
          </div>

          <MainButton
            sx={{
              width: "100%",
            }}
            onClick={() => {
              if (product?.quantity === 0) {
                toast.error(t("Restocking"));
                return false;
              }
              handleAddItem(quantity, product?.id);
            }}
          >
            {product?.quantity > 0 && (
              <span style={{ display: "inline-block", margin: "0 8px" }}>
                +
              </span>
            )}
            {product?.quantity === 0 ? t("Restocking") : t("AddToCart")}
          </MainButton>
        </Box>

        <Box>
          {(product.material ||
            product?.package_dimensions ||
            product?.kilograms_capacity ||
            product?.number_of_excitations ||
            product?.special_features ||
            product?.item_weight ||
            product?.date_first_available) && (
            <Typography
              sx={{ fontSize: "18px", fontWeight: "600", mb: "24px" }}
            >
              {t("Technical Details")}
            </Typography>
          )}
          <BasicTable tech={product} />
        </Box>
      </Box>
      <Box sx={{ justifySelf: "end" }}>
        <Box>
          <IconBox
            sx={{
              width: "48px !important",
              height: "48px !important",
              mb: "16px",
              ml: "16px",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              background:
                isHoveringSaved || product?.is_wishlist
                  ? "linear-gradient(90deg, #AF1016 0%, #DC1820 100%)!important"
                  : "#fff",
              color: isHoveringSaved || product?.is_wishlist ? "#fff" : "#000",
            }}
            onMouseOver={() => setIsHoveringSaved(true)}
            onMouseOut={() => setIsHoveringSaved(false)}
            onClick={handleSaveItem}
          >
            <BsBookmark style={{ fontSize: "18px" }} />
          </IconBox>
          <input
            type="text"
            value="https://www.example.com"
            ref={linkRef}
            readOnly
            style={{ display: "none" }}
          />
          <IconBox
            sx={{
              width: "48px !important",
              height: "48px !important",
              ml: "16px",
              filter: "drop-shadow(0px 4px 10px rgba(0, 0, 0, 0.08))",
              background: isHoveringCopied
                ? "linear-gradient(90deg, #AF1016 0%, #DC1820 100%)!important"
                : "#fff",
              color: isHoveringCopied ? "#fff" : "#000",
            }}
            onMouseOver={() => setIsHoveringCopied(true)}
            onMouseOut={() => setIsHoveringCopied(false)}
            onClick={copyToClipboard}
          >
            <IosShareIcon />
          </IconBox>
        </Box>
      </Box>
    </Stack>
  );
};

export default ProductsInfo;
