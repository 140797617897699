import React from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import PasswordField from "../common/passwordField";
import PhoneField from "../common/phoneField";
import { t } from "i18next";
import ControlField from "../common/controlField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { useFormik } from "formik";
import * as Yup from "yup";
import { RequestVerifyChangePassword, RequestVerifyPassword, setErrorMsg } from "../../store/user/user";
import Cookies from "js-cookie";
import { setOpenLogin, setSignType } from "../../store/globalSlice";

const VerfiyForgetPassword = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { error, errorMsg, errorVerfiyForget } = useSelector(
    (state: RootState) => state.user
  );
  const formik = useFormik({
    initialValues: {
      otp: "",
    },
    validationSchema: Yup.object().shape({}),
    onSubmit: (values) => {
      const { otp } = values;
      dispatch(
        RequestVerifyChangePassword({
          otp: otp + "",
          phone: localStorage.getItem("phone")
            ? localStorage.getItem("phone")
            : "",
          action: "forget password",
        })
      ).then((req) => {
        {
          if (req.type == "RequestVerifyChangePassword/fulfilled") {
            dispatch(setErrorMsg(""));
            dispatch(setSignType("reset"))
            localStorage.setItem("otp", otp)
          }
        }
      });
    },
  });
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        height: "100%",
        justifyContent: "space-between",
      }}
    >
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
          {t("verify_mobile_number")}
        </Typography>
        <Typography sx={{ color: "#505050", mt: "8px", fontWeight: "400" }}>
          {t("verify_text")}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box mt="32px">
            <ControlField
              type="address"
              label={t("otp_code")}
              id="otp"
              name="otp"
              onChange={formik.handleChange}
              value={formik.values.otp}
              errors={error ? errorVerfiyForget : ""}
            />
          </Box>

          <button type="submit" style={{ display: "none" }}>
            test
          </button>
        </form>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => {
            formik.handleSubmit();
          }}
        >
          {t("submit")}
        </MainButton>
      </Box>
    </Box>
  );
};

export default VerfiyForgetPassword;