import * as React from 'react';
import Box from '@mui/material/Box';
import Adver1 from '../../assets/slider/Advertisement.webp';

export default function Advertisement() {
  return (
    <Box component="section" sx={{ p: 2, border: '1px dashed none',textAlign: 'center'  }}>
        <img src={Adver1} width={"60%"} height={"100%"}/>
    </Box>
  );
}