import React, { useState } from "react";
import { Box, Typography } from "@mui/material";
import { MainButton } from "../../style/style";
import PasswordField from "../common/passwordField";
import PhoneField from "../common/phoneField";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestResetPassword, setErrorMsg } from "../../store/user/user";
import { t } from "i18next";
import { useFormik } from "formik";
import Cookies from "js-cookie";
import * as Yup from "yup";
import ControlField from "../common/controlField";
import { setOpenLogin, setSignType } from "../../store/globalSlice";

const Reset = () => {
  const dispatch = useDispatch<AppDispatch>();
  const { loading, errorMsg, errorReset, user} = useSelector((state: RootState) => state.user);

  const formik = useFormik({
    initialValues: {
      new_password: "",
      repassword: "",
    },
    validationSchema: Yup.object().shape({
      new_password: Yup.string()
        .required(`${t("pleaseenterpassword")}`)
        .matches(
          /^(?=.*[A-Za-z])(?=.*\d)/,
          `${t(`${t("Password must contain at least one letter and one number")}`)}`
        )
        .min(8, `${t("Password is too short - should be 8 chars minimum.")}`),
      repassword: Yup.string().oneOf(
        [Yup.ref("new_password")],
        `${t("Passwords must match")}`
      ),
    }),
    onSubmit: (values, { setSubmitting }) => {
      dispatch(
        RequestResetPassword({
          new_password: formik.values.new_password,
          phone: localStorage.getItem("phone")
            ? localStorage.getItem("phone")
            : "",
        })
      ).then((req) => {
        {
          if (req.type == "RequestResetPassword/fulfilled" && !user ) {
            dispatch(setErrorMsg(""));
            dispatch(setOpenLogin(true));
            dispatch(setSignType("login"));
          }
        }
      });
    },
  });
  return (
    <Box sx={{ display: "flex", flexDirection: "column", height: "100%", justifyContent: "space-between", }}>
      <Box>
        <Typography sx={{ fontSize: "36px", fontWeight: "600" }}>
        {t('choosenew')} <br />
          {t('password')}
        </Typography>
        <Typography sx={{ color: "#505050", mt: "8px", fontWeight: "400" }}>
          {t('almostdone')}
        </Typography>

        <form onSubmit={formik.handleSubmit}>
          <Box mt="32px">
            <PasswordField
              id="new_password"
              label={t("password")}
              name="new_password"
              onChange={formik.handleChange}
              value={formik.values.new_password}
              errors={
                formik.touched.new_password && formik.errors.new_password
                  ? formik.errors.new_password
                  : ""
              }
            />
          </Box>

          <Box mt="32px">
            <PasswordField
              id="repassword"
              label={t("re_password")}
              name="repassword"
              onChange={formik.handleChange}
              value={formik.values.repassword}
              errors={
                formik.touched.repassword && formik.errors.repassword
                  ? formik.errors.repassword
                  : ""
              }
            />

            <Typography sx={{ color: "red", mt: "10px" }}>
              {errorReset && errorReset}
            </Typography>
          </Box>

          <button type="submit" style={{ display: "none" }}>
            test
          </button>
        </form>
      </Box>

      <Box sx={{ mt: "40px" }}>
        <MainButton
          sx={{ width: "100%" }}
          onClick={() => formik.handleSubmit()}
        >
          {t("reset_password")}
        </MainButton>
      </Box>
    </Box>
  );
};

export default Reset;
