import React,{useEffect} from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Button from '@mui/material/Button';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import InboxIcon from '@mui/icons-material/MoveToInbox';
import MailIcon from '@mui/icons-material/Mail';
import { CheckBoxCheck } from './customCheck';
import Accordion from '../shop/accordion';
import i18next, { t } from 'i18next';
import AccordionRate from '../shop/accordionRate';
import AccordionCheck from '../shop/accordionCheck';
import AccordionContent from '../shop/accordionContent';
import { useDispatch, useSelector } from 'react-redux';
import { RequestGetCategories, RequestGetProductsTest, setVideoReviews } from '../../store/products/productsSlice';
import { AppDispatch, RootState } from '../../store/store';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';
import { SelectChangeEvent, Grid, Container } from '@mui/material';

type Anchor = 'top' | 'left' | 'bottom' | 'right';

export default function ShopFilterDrewer({open,onClose}:any) {
  const [state, setState] = React.useState({
    top: false,
    left: false,
    bottom: false,
    right: false,
  });
  const [isChecked, setIsChecked] = React.useState<string>("all");
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const lang = useSelector((state: RootState) => state.global.mainLang)


  const dispatch = useDispatch<AppDispatch>();

  const { loading, cate, filter, products, error, breadCate } = useSelector(
    (state: RootState) => state.products
  );
  const { color } = useSelector((state: RootState) => state.color);
  const { results }: any = useSelector(
    (state: RootState) => state.products.products
  );

  function handleChange(e: SelectChangeEvent) {
    setSelectResult(e.target.value);
  }
  const [selectResult, setSelectResult] = React.useState("Best seller");


  const toggleDrawer =
    (anchor: Anchor, open: boolean) =>
    (event: React.KeyboardEvent | React.MouseEvent) => {
      if (
        event.type === 'keydown' &&
        ((event as React.KeyboardEvent).key === 'Tab' ||
          (event as React.KeyboardEvent).key === 'Shift')
      ) {
        return;
      }

      setState({ ...state, [anchor]: open });
    };

  const list = (anchor: Anchor) => (
    <Box
      sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 350,}}
      role="presentation"
      // onClick={toggleDrawer(anchor, false)}
      onKeyDown={toggleDrawer(anchor, false)}
    >
      <Container
          >
            <Box
              sx={{
                // background: "rgba(251, 251, 251, 1)",
                height: "100%",
                borderRadius: "10px",
                padding: "16px",
                mt:"52px",
                direction:i18next.language==="en"?"ltr":"rtl",
              }}
            >
              <Accordion
                text={t("price")}
                content={<AccordionContent filter={filter} />}
              />
              <Accordion
                text={t("color")}
                content={
                  <AccordionCheck
                    items={color}
                    filter={filter}
                    type="color_ids"
                  />
                }
              />
              <Accordion
                text={t("rate")}
                content={<AccordionRate type="avg_rating" filter={filter} />}
              />
              <Accordion
                text={t("categories")}
                content={
                  <AccordionCheck
                    items={cate}
                    type="category_ids"
                    filter={filter}
                  />
                }
              />
              <Accordion
                  text={t("video review")}
                  content={
                    <>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          padding: "0 10px",
                          mb: "15px",
                        }}
                        onClick={() => {
                          setIsChecked("t");
                          dispatch(setVideoReviews(true));
                        }}
                      >
                        <CheckBoxCheck className={`${filter.video_review !== "false" ? "container main-outline-btn" : ""}`}>
                          {filter.video_review !== "false" && (
                            <span className="main-text">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="8"
                                viewBox="0 0 10 8"
                                fill="none"
                              >
                                <path
                                  d="M8.75 0.875L3.5 7.125L1.25 4.625"
                                  stroke="url(#paint0_linear_57_2520)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_57_2520"
                                    x1="1.25"
                                    y1="3.93056"
                                    x2="8.74944"
                                    y2="3.99523"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#F5841F" />
                                    <stop offset="1" stopColor="#ED1C24" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                          )}
                        </CheckBoxCheck>
                        <span>true</span>
                      </Box>
                      <Box
                        sx={{
                          display: "flex",
                          gap: "10px",
                          alignItems: "center",
                          padding: "0 10px",
                          mb: "15px",
                        }}
                        onClick={() => {
                          setIsChecked("f");
                          dispatch(setVideoReviews("false"));
                        }}
                      >
                        <CheckBoxCheck className={`${filter.video_review == "false" ? "container main-outline-btn" : ""}`}>
                          {filter.video_review == "false" && (
                            <span className="main-text">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="10"
                                height="8"
                                viewBox="0 0 10 8"
                                fill="none"
                              >
                                <path
                                  d="M8.75 0.875L3.5 7.125L1.25 4.625"
                                  stroke="url(#paint0_linear_57_2520)"
                                  strokeWidth="1.5"
                                  strokeLinecap="round"
                                  strokeLinejoin="round"
                                />
                                <defs>
                                  <linearGradient
                                    id="paint0_linear_57_2520"
                                    x1="1.25"
                                    y1="3.93056"
                                    x2="8.74944"
                                    y2="3.99523"
                                    gradientUnits="userSpaceOnUse"
                                  >
                                    <stop stopColor="#F5841F" />
                                    <stop offset="1" stopColor="#ED1C24" />
                                  </linearGradient>
                                </defs>
                              </svg>
                            </span>
                          )}
                        </CheckBoxCheck>
                        <span>false</span>
                      </Box>
                    </>
                  }
                />
            </Box>
          </Container>
    </Box>
  );

  return (
    <div>
      
          {/* <Button onClick={toggleDrawer(anchor, true)}>{anchor}</Button> */}
          <Drawer
            anchor="right"
            open={open}
            onClose={() => onClose && onClose()}
          >
            {list('right')}
          </Drawer>
       
     
    </div>
  );
}