import { Box, Typography, OutlinedInput } from "@mui/material";
import React from "react";
import InputAdornment from "@mui/material/InputAdornment";
import IconButton from "@mui/material/IconButton";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
interface PasswordFieldProps {
  label?: string;
  sx?: {};
  onChange?: any;
  value?: string;
  name?: string;
  errors?: string;
  errorMsg?: string;
  id?: string;
}

const PasswordField = ({
  label,
  sx,
  onChange,
  value,
  name,
  errors,
  errorMsg,
  id = "outlined-adornment-password",
}: PasswordFieldProps) => {
  const [showPassword, setShowPassword] = React.useState(false);

  const handleClickShowPassword = () => setShowPassword((show) => !show);

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };
  return (
    <>
      {label && (
        <Typography
          sx={{
            fontSize: "16px",
            fontWeight: "500",
            mb: "8px",
            textTransform: "capitalize",
          }}
        >
          {label}
        </Typography>
      )}
      <OutlinedInput
        label=""
        id={id}
        name={name}
        value={value}
        fullWidth
        type={showPassword ? "text" : "password"}
        onChange={onChange}
        sx={{
          "&.MuiInputBase-root": {
            background: "#F2F2F2",
            borderRadius: "12px",
            borderColor: "#F2F2F2",
          },
          "& .MuiOutlinedInput-notchedOutline": {
            border: "none",
          },
          "&.Mui-focused.MuiOutlinedInput-root.MuiInputBase-root": {
            background: "#fff",
            boxShadow:
              "2px 2px 10px 0px rgba(237, 28, 36, 0.16), -2px -2px 10px 0px rgba(237, 28, 36, 0.16)",
          },
          ...sx,
        }}
        endAdornment={
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge="end"
            >
              {showPassword ? (
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="22"
                  height="18"
                  viewBox="0 0 22 18"
                  fill="none"
                >
                  <path
                    d="M0.999512 1.27L2.27951 0L18.9995 16.72L17.7295 18L14.6495 14.92C13.4995 15.3 12.2795 15.5 10.9995 15.5C5.99951 15.5 1.72951 12.39 -0.000488281 8C0.689512 6.24 1.78951 4.69 3.18951 3.46L0.999512 1.27ZM10.9995 5C11.7952 5 12.5582 5.31607 13.1208 5.87868C13.6834 6.44129 13.9995 7.20435 13.9995 8C14 8.34057 13.9425 8.67873 13.8295 9L9.99951 5.17C10.3208 5.05698 10.6589 4.9995 10.9995 5ZM10.9995 0.5C15.9995 0.5 20.2695 3.61 21.9995 8C21.1834 10.0732 19.7965 11.8727 17.9995 13.19L16.5795 11.76C17.9624 10.8034 19.0777 9.50906 19.8195 8C19.0111 6.34994 17.7559 4.95977 16.1968 3.98751C14.6376 3.01524 12.837 2.49988 10.9995 2.5C9.90951 2.5 8.83951 2.68 7.83951 3L6.29951 1.47C7.73951 0.85 9.32951 0.5 10.9995 0.5ZM2.17951 8C2.98795 9.65006 4.24308 11.0402 5.80224 12.0125C7.3614 12.9848 9.16205 13.5001 10.9995 13.5C11.6895 13.5 12.3695 13.43 12.9995 13.29L10.7195 11C10.0237 10.9254 9.37434 10.6149 8.87949 10.12C8.38464 9.62518 8.0741 8.97584 7.99951 8.28L4.59951 4.87C3.60951 5.72 2.77951 6.78 2.17951 8Z"
                    fill="#1B1B1B"
                  />
                </svg>
              ) : (
                <Visibility sx={{color:"#1B1B1B"}}/>
              )}
            </IconButton>
          </InputAdornment>
        }
      />

      <Typography component="p" sx={{ color: "red", mt: "1px" }}>
        {errors}
      </Typography>
    </>
  );
};

export default PasswordField;
