import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";
import Iframe from "react-iframe";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

import CircularProgress from "@mui/material/CircularProgress";
import { AnyCnameRecord } from "dns";
import useMediaQuery from '@mui/material/useMediaQuery';
const style = {
  position: "absolute" as "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  // width: 1000,
  p: 4,
};

export default function BasicModal({
  open,
  onClose,
}: {
  open: boolean;
  onClose: any;
}) {
  //   const [open, setOpen] = React.useState(false);
  //   const handleOpen = () => setOpen(true);
  //   const handleClose = () => setOpen(false);
  const paymentUrl = useSelector((state: RootState) => state.global.paymentUrl);
  const paymentLoading = useSelector(
    (state: RootState) => state.global.paymentLoading
  );
  const loading = useSelector((state: RootState) => state.payment.loading);

  const matches = useMediaQuery('(min-width:600px)');
  
  return (
    <div>
      {/* <Button onClick={handleOpen}>Open modal</Button> */}
      <Modal
        open={open}
        onClose={() => onClose()}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box
          sx={{
            ...style,
            margin: "auto",
            "& iframe": { width: matches ? "600px" : "auto", height:"100%", background:"#f8f9fa" },
            "& .iframeOut": {margin:"0 !important"},
            height:"100%"

          }}
        >
          {paymentLoading ? (
            <Box sx={{ display: "flex" }}>
              <CircularProgress />
            </Box>
          ) : (
            <iframe
              id="your-iframe-id"
              src={paymentUrl}
              width="auto"
              height="500px"
              className="iframe-payment"
              style={{ display: "block", position: "relative" }}
            />
          )}
        </Box>
      </Modal>
    </div>
  );
}
