// import { BASEURL } from '@/data/APIS';
import { createSlice, createAsyncThunk, current } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BASEURL } from "../../data/apis";
import { ProductProps } from "../../types/product";

import customFetch from "../../utils/axios";
import { RootState } from "../store";
import { stat } from "fs";
import { t } from "i18next";


export interface ProductsState {
  loading: boolean;
  error: boolean;
  products: any;
  errorMsg: String;
  filter: filterData;
  cate: any;
  breadCate: string;
  cateLoading: boolean
}

const initialState: ProductsState = {
  loading: false,
  products: [],
  error: false,
  errorMsg: "",
  cate: [],
  cateLoading: false,
  breadCate: localStorage.getItem("lang") === "ar" ? "الكل" : "All",
  filter: {
    color_ids: [],
    search: "",
    price__gte: "",
    price__lte: "",
    video_review: "",
    category_ids: [],
    rating: [],
    limit: "10",
    page: "1",
    ordering: "-price",
    avg_rating: []
  },
};

export interface filterData {
  category_ids?: Array<number>;
  video_review?: string;
  search?: string;
  color_ids?: Array<number>;
  price__gte?: string;
  price__lte?: string;
  rating: Array<number>;
  avg_rating: Array<number>;
  [key: string]: any;
  ordering?: string,
}
interface SetFilterPayload {
  type: keyof filterData;
  val: any; // Replace 'any' with the appropriate type of 'val'
}
export const RequestGetProductsTest = createAsyncThunk(
  "RequestGetProductsTest",
  async (filterProduct: any, ThunkApi) => {
    const state: any = ThunkApi.getState();
    const filter = state.products.filter;
    const token = state.user.user;

    let params: any = {};

    const config = {
      headers: {
        Authorization: `Token ${token}`,
      }
    }

    if (filterProduct.category_ids.length > 0) {
      params.category_ids = [...filterProduct?.category_ids].join(",");
    }

    if (filterProduct.rating.length > 0) {
      params.rating = [...filterProduct?.rating].join(",");
    }

    if (filterProduct.avg_rating.length > 0) {
      params.avg_rating = [...filterProduct?.avg_rating].join(",");
    }

    if (filterProduct.color_ids.length > 0) {
      params.color_ids = [...filterProduct?.color_ids].join(",");
    }

    if (filterProduct.video_review) {
      params.video_review = filter.video_review;
    }

    if (filterProduct.price__lte) {
      params.price__lte = filter.price__lte;
    }

    if (filterProduct.price__gte) {
      params.price__gte = filter.price__gte;
    }

    if (filterProduct.search) {
      params.search = filter.search;
    }

    if (filterProduct.page && filterProduct.page !== "1") {
      params.page = filter.page;
    }

    if (filterProduct.ordering !== "") {
      params.ordering = filter.ordering;
    }


    try {
      if (token) {
        const response = await axios.get(`${BASEURL}/products/`, { params, ...config });
        return response.data;
      } else {

        const response = await axios.get(`${BASEURL}/products/`, { params});
        return response.data;
      }
    } catch (error: any) {

      return ThunkApi.rejectWithValue(error.message);
    }
  }
);

// https://seashell-app-q7vi5.ondigitalocean.app/category/
export const RequestGetCategories = createAsyncThunk(
  "RequestGetCategories",
  async (_, ThunkApi) => {
    try {
      const response = await axios.get(`${BASEURL}/category/`);
      return response.data;
    } catch (error: any) {
      return ThunkApi.rejectWithValue(error.data);
    }
  }
);

export const productsSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setFilter: (state, action: PayloadAction<SetFilterPayload>) => {
      if (!state.filter[action.payload.type].includes(action.payload.val)) {
        state.filter[action.payload.type] = [
          ...state.filter[action.payload.type],
          action.payload.val,
        ];
      } else {
        state.filter[action.payload.type] = state.filter[action.payload.type];
      }
      state.filter.page = "1"
    },
    setFilterRemove: (state, action: PayloadAction<SetFilterPayload>) => {
      if (state.filter[action.payload.type].includes(action.payload.val)) {
        state.filter[action.payload.type] = state.filter[
          action.payload.type
        ].filter((ele: any) => ele != action.payload.val);
      }
      state.filter.page = "1"
    },
    setCategoryID: (state, action) => {
      state.filter.category_ids = action.payload
    },
    setSearch : (state, action) => {
      state.filter.page = "1"
      state.filter.search = action.payload
    },
    setVideoReviews: (state, action:PayloadAction<any>) => {
      state.filter.page = "1"
      state.filter.video_review = action.payload
    },
    setPriceRange: (state, action) => {
      state.filter.page = "1"
      state.filter.price__lte = action.payload.lte
      state.filter.price__gte = action.payload.gte
    },
    setBread: (state, action) => {
      state.breadCate = action.payload
    },
    setPage: (state, action) => {
      state.filter.page = action.payload
    },
    setValuesFilter: (state, action) => {
      state.filter.page = "1"
      state.filter[action.payload.type] = action.payload.val
    },
    setProduct: (state, action) => {
      state.products = {
        ...state?.products,
        results: state?.products?.results?.map((ele: any) =>
          ele.id === action?.payload?.id
            ? { ...ele, ...action?.payload?.product }
            : ele
        ),
      };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(RequestGetCategories.pending, (state) => {
        state.loading = true;
        state.cateLoading = true;
        state.error = false;
        state.cate = [];
      })
      .addCase(RequestGetCategories.fulfilled, (state, action) => {
        state.loading = false;
        state.cateLoading = false;
        state.cate = action.payload;
      })
      .addCase(RequestGetCategories.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
        state.cateLoading = false;
      });
    builder
      .addCase(RequestGetProductsTest.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.products = [];
      })
      .addCase(RequestGetProductsTest.fulfilled, (state, action) => {
        state.loading = false;
        state.products = action.payload;
      })
      .addCase(RequestGetProductsTest.rejected, (state, action: any) => {
        state.loading = false;
        state.error = action.payload;
      });
  },
});

// Action creators are generated for each case reducer function
export const { setFilter, setSearch, setVideoReviews, setPriceRange, setPage, setBread, setValuesFilter} = productsSlice.actions;

export default productsSlice.reducer;
