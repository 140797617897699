import React, { useEffect } from "react";
import { Box } from "@mui/material";

import Checkbox from "@mui/material/Checkbox";
import CustomCheck from "../common/customCheck";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestGetColor } from "../../store/color/colorSlice";
import { filterData } from "../../store/products/productsSlice";

const label = { inputProps: { "aria-label": "Checkbox demo" } };

interface AccordionCheckState {
  items?: any;
  type?: string;
  filter?: filterData;
}



const AccordionCheck = ({ items, type, filter }: AccordionCheckState) => {
  const dispatch = useDispatch<AppDispatch>();

  return (
    <Box sx={{mb:"20px"}}>
      {items?.map((item: any) => {
        return (
          <CustomCheck
            id={item.id}
            name={item.product_color ? item.product_color : item?.name}
            key={item?.id}
            filter={filter}
            type={type}
          />
        );
      })}
    </Box>
  );
};

export default AccordionCheck;
