import * as React from "react";
import Paper from "@mui/material/Paper";
import InputBase from "@mui/material/InputBase";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import SearchIcon from "@mui/icons-material/Search";
import DirectionsIcon from "@mui/icons-material/Directions";
import { t } from "i18next";
import { AppDispatch, RootState } from "../../store/store";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { setOpenDrawerSearch } from "../../store/globalSlice";
import {
  RequestGetProductsTest,
  setBread,
} from "../../store/products/productsSlice";
import { Box, CircularProgress, Typography } from "@mui/material";

export default function CustomizedInputBase() {
  const [search, setSearch] = React.useState("");
  const [isInputFocused, setIsInputFocused] = React.useState(false);
  const dispatch = useDispatch<AppDispatch>();
  const navigate = useNavigate();
  const lang = useSelector((state: RootState) => state.global.mainLang);
  const { filter, loading, products } = useSelector(
    (state: RootState) => state.products
  );

  const inputRef = React.useRef(null); // Create a ref to the input element
  const suggestionBoxRef = React.useRef(null); // Create a ref to the suggestion 
  const [isSuggestionBoxVisible, setIsSuggestionBoxVisible] =
    React.useState<boolean>(false);

  React.useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (
        inputRef.current &&
        !((inputRef.current as unknown) as HTMLElement).contains(
          event.target as Node
        ) &&
        suggestionBoxRef.current &&
        !((suggestionBoxRef.current as unknown) as HTMLElement).contains(
          event.target as Node
        )
      ) {
        setIsInputFocused(false);
        setIsSuggestionBoxVisible(false);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);

    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <Box sx={{ width: "100%", position: "relative" }}>
      <Paper
        component="form"
        sx={{
          p: "0px 4px",
          display: "flex",
          alignItems: "center",
          width: "100%",
          border: "0.5px solid #8A8A8A",
          borderRadius: "10px",
          boxShadow: "0px 0px 0px 8px rgba(255, 255, 255, 0.20)",
          direction: lang == "en" ? "ltr" : "rtl",
        }}
        onSubmit={(e) => {
          e.preventDefault();
          dispatch({ type: "products/setSearch", payload: search });
          navigate("shop");
          dispatch(setBread(search));
          dispatch(setOpenDrawerSearch(false));
        }}
      >
        <InputBase
          sx={{
            ml: 1,
            flex: 1,
            "& ::placeholder": { fontWeight: "500", color: "#505050" },
            padding: "10px 24px",
          }}
          placeholder={t("Serach...")}
          inputProps={{ "aria-label": "Serach...." }}
          onChange={(e: any) => {
            setSearch(e.target.value);
            dispatch({ type: "products/setSearch", payload: e.target.value });
            dispatch(RequestGetProductsTest({ ...filter, search: e.target.value }));
          }}
          onFocus={() => {
            setIsInputFocused(true)
            setIsSuggestionBoxVisible(true);
          }}
          ref={inputRef}
        // onBlur={() => setIsInputFocused(false)}
        />
        <IconButton
          type="button"
          sx={{ p: "10px", padding: "0 21px" }}
          aria-label="search"
          onClick={() => {
            dispatch({ type: "products/setSearch", payload: search });
            navigate("shop");
          }}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="14"
            height="13"
            viewBox="0 0 14 13"
            fill="none"
          >
            <g clipPath="url(#clip0_3737_9507)">
              <path
                d="M5.52201 8.96101C7.69746 8.96101 9.46101 7.19746 9.46101 5.02201C9.46101 2.84656 7.69746 1.08301 5.52201 1.08301C3.34656 1.08301 1.58301 2.84656 1.58301 5.02201C1.58301 7.19746 3.34656 8.96101 5.52201 8.96101Z"
                stroke="black"
                strokeWidth="1.625"
              />
              <path
                d="M8.47754 7.97754L11.9594 11.4594"
                stroke="black"
                strokeWidth="1.625"
                strokeLinecap="square"
              />
            </g>
            <defs>
              <clipPath id="clip0_3737_9507">
                <rect
                  width="13"
                  height="13"
                  fill="white"
                  transform="translate(0.5)"
                />
              </clipPath>
            </defs>
          </svg>
          {/* <SearchIcon /> */}
        </IconButton>
      </Paper>
      <Box
        sx={{
          boxShadow: "2px 2px 10px 0px rgba(0, 0, 0, 0.08)",
          padding: "15px",
          background: "#fff",
          position: "absolute",
          top: "60px",
          width: "100%",
          minHeight: "50px",
          maxHeight: "300px",
          borderRadius: "15px",
          overflow: "auto",
          display: isInputFocused ? "block" : "none",
          transition: "0.3s all"
        }}
        ref={suggestionBoxRef}
      >
        {loading && (
          <Box sx={{ display: "flex", justifyContent: "center" }}>
            <CircularProgress />
          </Box>
        )}
        {products?.results?.map((product: any) => {
          return (
            <Box
              sx={{
                borderBottom: "0.5px solid #cfcfcf47",
                marginBottom: "5px",
                padding: "5px",
                textAlign: "right",
              }}
            >
              <Link
                to={`shop/${product?.id}`}
                style={{ textDecoration: "none", color: "inherit" }}
                onClick={(e) => {
                  setIsInputFocused(false)
                }}
              >
                {product?.name}
              </Link>
            </Box>
          );
        })}

        {products?.results?.length === 0 && <Typography sx={{ textAlign: "right" }}>لا يوجد</Typography>}
      </Box>
    </Box>
  );
}
