import { Box, Typography } from "@mui/material";
import { H2 } from "../../style/style";
import reviewImage1 from "../../assets/vedio-review/review-1.png";
import rafalImage from "../../assets/vedio-review/review-2.png";
import rafalImage2 from "../../assets/vedio-review/review-3.png";
import Slider from "react-slick";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Link } from "react-router-dom";
import { VideoProps } from "../../types/video";
import { useSelector } from "react-redux";
import { RootState } from "../../store/store";

const VideoCard = ({ video }: { video: VideoProps }) => {
  const lang = useSelector((state: RootState) => state.global.mainLang)

  return (
    <>
      <Box sx={{direction: lang == "ar" ? "rtl" : "ltr"}}>
        <Box
          sx={{
            boxShadow:
              "0px 1px 2px 0px rgba(16, 24, 40, 0.06), 0px 1px 3px 0px rgba(16, 24, 40, 0.10)",
              background:"#fff",
            display: "flex",
            gap: "14px",
            borderRadius: "8px",
            padding: "9px",
          }}
        >
          <Box sx={{ position: "relative" }}>
            <img
              src={video?.vid_image_website}
              alt={video?.video_name}
              style={{ width: "68px", height: "68px" }}
            />
            <Link to={`${video?.video}`}>
              <Box
                sx={{
                  position: "absolute",
                  top: "0",
                  left: "0",
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  width: "100%",
                  height: "100%",
                }}
              >
                <PlayArrowIcon sx={{ color: "#fff" }} />
              </Box>
            </Link>
          </Box>
          <Box sx={{flex:"1", overflow:"hidden"}}>
            <Typography
              sx={{
                fontSize: "18px",
                fontWeight: "500",
                whiteSpace: "nowrap",
                overflow: "hidden",
                textOverflow: "ellipsis",
              }}
            >
              {video?.author_vid_name}
            </Typography>
            <Typography
              sx={{
                fontSize: "16px",
                fontWeight: "300",
                whiteSpace: "nowrap",
                overflow: "hidden",
                // textAlign:"right",
                textOverflow: "ellipsis",
              }}
            >
              {video?.video_name}
            </Typography>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default VideoCard;
