// import { BASEURL } from '@/data/APIS';
import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";
import type { PayloadAction } from "@reduxjs/toolkit";

import { BASEURL } from "../../data/apis";
import customFetch from "../../utils/axios";
import { ProductProps } from "../../types/product";
interface colorType {
  id: number;
  product_color: string;
}
export interface ColorState {
  loading: boolean;
  error: boolean;
  color: Array<colorType>;
  errorMsg: String;
}

const initialState: ColorState = {
  loading: false,
  color: [],
  error: false,
  errorMsg: "",
};

export const RequestGetColor = createAsyncThunk(
  "RequestGetColor",
  async (_, { getState, rejectWithValue }) => {
    try {
      const response = await axios.get(`${BASEURL}/color/`);

      return response.data;
    } catch (error: any) {

      return rejectWithValue(error.data);
    }
  }
);

export const colorSlice = createSlice({
  name: "color",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(RequestGetColor.pending, (state) => {
        state.loading = true;
        state.error = false;
        state.color = [];
      })
      .addCase(RequestGetColor.fulfilled, (state, action) => {
        state.loading = false;
        state.color = action.payload;
      })
      .addCase(RequestGetColor.rejected, (state, action: any) => {
        state.loading = false;
        state.error = false;
      });
  },
});

// Action creators are generated for each case reducer function

export default colorSlice.reducer;
