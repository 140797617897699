export const savedIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="26"
    height="26"
    viewBox="0 0 26 26"
    fill="none"
  >
    <path
      d="M6.20755 23C5.5434 23 5 22.46 5 21.8V6.2C5 3.89 6.90189 2 9.22642 2H16.7736C19.0981 2 21 3.89 21 6.2V21.74C21 22.07 20.8792 22.37 20.6377 22.61C20.4264 22.82 20.1245 22.94 19.8226 22.94C19.4906 22.94 19.1887 22.82 18.9472 22.58L13.1811 16.52L7.02264 22.64C6.81132 22.88 6.50943 23 6.20755 23ZM9.22642 3.23C7.56604 3.23 6.20755 4.58 6.20755 6.23V21.77L13.2113 14.81L19.7925 21.71V6.23C19.7925 4.58 18.434 3.23 16.7736 3.23H9.22642Z"
      fill="#1B1B1B"
    />
  </svg>
);

export const transferIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="29"
    height="26"
    viewBox="0 0 29 26"
    fill="none"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M16.3718 8.44535C16.4268 8.50023 16.4705 8.56543 16.5003 8.63721C16.5301 8.70899 16.5454 8.78594 16.5454 8.86365C16.5454 8.94137 16.5301 9.01832 16.5003 9.0901C16.4705 9.16188 16.4268 9.22708 16.3718 9.28196L11.6449 14.0086C11.534 14.1195 11.3835 14.1818 11.2266 14.1818C11.0697 14.1818 10.9192 14.1195 10.8083 14.0086C10.6973 13.8976 10.635 13.7472 10.635 13.5903C10.635 13.4334 10.6973 13.2829 10.8083 13.172L14.5272 9.45448L4.13627 9.45448C3.97957 9.45448 3.82928 9.39223 3.71847 9.28143C3.60766 9.17063 3.54541 9.02035 3.54541 8.86365C3.54541 8.70696 3.60766 8.55668 3.71847 8.44588C3.82928 8.33507 3.97957 8.27283 4.13627 8.27283L14.5272 8.27283L10.8083 4.55535C10.7533 4.50042 10.7098 4.4352 10.68 4.36343C10.6503 4.29166 10.635 4.21473 10.635 4.13704C10.635 4.05936 10.6503 3.98243 10.68 3.91066C10.7098 3.83889 10.7533 3.77367 10.8083 3.71874C10.8632 3.66381 10.9284 3.62023 11.0002 3.5905C11.072 3.56077 11.1489 3.54547 11.2266 3.54547C11.3043 3.54547 11.3812 3.56077 11.453 3.5905C11.5248 3.62023 11.59 3.66381 11.6449 3.71874L16.3718 8.44535Z"
      fill="#1B1B1B"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M11.9919 17.5547C11.9369 17.4998 11.8933 17.4346 11.8635 17.3628C11.8337 17.291 11.8184 17.2141 11.8184 17.1363C11.8184 17.0586 11.8337 16.9817 11.8635 16.9099C11.8933 16.8381 11.9369 16.7729 11.9919 16.718L16.7188 11.9914C16.8298 11.8805 16.9803 11.8182 17.1372 11.8182C17.2941 11.8182 17.4445 11.8805 17.5555 11.9914C17.6664 12.1024 17.7288 12.2528 17.7288 12.4097C17.7288 12.5666 17.6664 12.7171 17.5555 12.828L13.8366 16.5455L24.2275 16.5455C24.3842 16.5455 24.5345 16.6078 24.6453 16.7186C24.7561 16.8294 24.8184 16.9797 24.8184 17.1363C24.8184 17.293 24.7561 17.4433 24.6453 17.5541C24.5345 17.6649 24.3842 17.7272 24.2275 17.7272L13.8366 17.7272L17.5555 21.4447C17.6104 21.4996 17.654 21.5648 17.6837 21.6366C17.7135 21.7083 17.7288 21.7853 17.7288 21.863C17.7288 21.9406 17.7135 22.0176 17.6837 22.0893C17.654 22.1611 17.6104 22.2263 17.5555 22.2813C17.5006 22.3362 17.4353 22.3798 17.3636 22.4095C17.2918 22.4392 17.2149 22.4545 17.1372 22.4545C17.0595 22.4545 16.9825 22.4392 16.9108 22.4095C16.839 22.3798 16.7738 22.3362 16.7188 22.2813L11.9919 17.5547Z"
      fill="#1B1B1B"
    />
  </svg>
);

export const uploadIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="33"
    height="32"
    viewBox="0 0 33 32"
    fill="none"
  >
    <path
      d="M21.25 12H23.75C24.413 12 25.0489 12.2634 25.5178 12.7322C25.9866 13.2011 26.25 13.837 26.25 14.5V26.5C26.25 27.163 25.9866 27.7989 25.5178 28.2678C25.0489 28.7366 24.413 29 23.75 29H8.75C8.08696 29 7.45107 28.7366 6.98223 28.2678C6.51339 27.7989 6.25 27.163 6.25 26.5V14.5C6.25 13.837 6.51339 13.2011 6.98223 12.7322C7.45107 12.2634 8.08696 12 8.75 12H11.25M21.25 8L16.25 3M16.25 3L11.25 8M16.25 3V20.0625"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

/* Navbar */

export const notificationIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <path
      d="M19.8638 8.66669C19.8638 6.94278 19.179 5.28948 17.96 4.07049C16.741 2.85151 15.0877 2.16669 13.3638 2.16669C11.6399 2.16669 9.98656 2.85151 8.76758 4.07049C7.54859 5.28948 6.86377 6.94278 6.86377 8.66669C6.86377 16.25 3.61377 18.4167 3.61377 18.4167H23.1138C23.1138 18.4167 19.8638 16.25 19.8638 8.66669Z"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M15.2381 22.75C15.0476 23.0783 14.7742 23.3509 14.4453 23.5403C14.1164 23.7298 13.7435 23.8295 13.3639 23.8295C12.9843 23.8295 12.6114 23.7298 12.2825 23.5403C11.9536 23.3509 11.6802 23.0783 11.4897 22.75"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const cartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <g clipPath="url(#clip0_1645_4762)">
      <path
        d="M4.69727 20.5834C4.69727 21.158 4.92554 21.7091 5.33187 22.1154C5.7382 22.5217 6.2893 22.75 6.86393 22.75C7.43857 22.75 7.98967 22.5217 8.396 22.1154C8.80233 21.7091 9.0306 21.158 9.0306 20.5834C9.0306 20.0087 8.80233 19.4576 8.396 19.0513C7.98967 18.645 7.43857 18.4167 6.86393 18.4167C6.2893 18.4167 5.7382 18.645 5.33187 19.0513C4.92554 19.4576 4.69727 20.0087 4.69727 20.5834Z"
        stroke="#1B1B1B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M16.6138 20.5834C16.6138 21.158 16.842 21.7091 17.2484 22.1154C17.6547 22.5217 18.2058 22.75 18.7804 22.75C19.3551 22.75 19.9062 22.5217 20.3125 22.1154C20.7188 21.7091 20.9471 21.158 20.9471 20.5834C20.9471 20.0087 20.7188 19.4576 20.3125 19.0513C19.9062 18.645 19.3551 18.4167 18.7804 18.4167C18.2058 18.4167 17.6547 18.645 17.2484 19.0513C16.842 19.4576 16.6138 20.0087 16.6138 20.5834Z"
        stroke="#1B1B1B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.7806 18.4167H6.86393V3.25H4.69727"
        stroke="#1B1B1B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M6.86377 5.41669L22.0304 6.50002L20.9471 14.0834H6.86377"
        stroke="#1B1B1B"
        strokeWidth="1.3"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1645_4762">
        <rect
          width="26"
          height="26"
          fill="white"
          transform="translate(0.36377)"
        />
      </clipPath>
    </defs>
  </svg>
);

export const closeCartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="22"
    height="22"
    viewBox="0 0 22 22"
    fill="none"
  >
    <path
      d="M19.0552 21.1309L10.9874 12.9313L2.93279 21.1309C2.41867 21.6451 1.57497 21.6451 1.07403 21.1178C0.599451 20.6168 0.599452 19.8258 1.08721 19.3381L9.12866 11.0066L1.08721 2.76743C0.599452 2.27967 0.599451 1.4887 1.07403 0.987762C1.57497 0.460453 2.41866 0.44727 2.91961 0.974578L10.9874 9.1874L19.0552 0.895481C19.5694 0.368173 20.4131 0.368173 20.914 0.895481C21.4018 1.39642 21.4018 2.20057 20.914 2.70151L12.8198 10.9934L20.9008 19.3117C21.3886 19.8127 21.3886 20.6168 20.9008 21.1178C20.3999 21.6451 19.5694 21.6451 19.0552 21.1309Z"
      fill="url(#paint0_linear_612_15219)"
    />
    <defs>
      <linearGradient
        id="paint0_linear_612_15219"
        x1="0.719727"
        y1="10.7739"
        x2="21.2788"
        y2="10.9185"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5841F" />
        <stop offset="1" stopColor="#ED1C24" />
      </linearGradient>
    </defs>
  </svg>
);

export const trashCartIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M16 1.75V3H21.25C21.4489 3 21.6397 3.07902 21.7803 3.21967C21.921 3.36032 22 3.55109 22 3.75C22 3.94891 21.921 4.13968 21.7803 4.28033C21.6397 4.42098 21.4489 4.5 21.25 4.5H2.75C2.55109 4.5 2.36032 4.42098 2.21967 4.28033C2.07902 4.13968 2 3.94891 2 3.75C2 3.55109 2.07902 3.36032 2.21967 3.21967C2.36032 3.07902 2.55109 3 2.75 3H8V1.75C8 0.784 8.784 0 9.75 0H14.25C15.216 0 16 0.784 16 1.75ZM9.5 1.75V3H14.5V1.75C14.5 1.6837 14.4737 1.62011 14.4268 1.57322C14.3799 1.52634 14.3163 1.5 14.25 1.5H9.75C9.6837 1.5 9.62011 1.52634 9.57322 1.57322C9.52634 1.62011 9.5 1.6837 9.5 1.75ZM4.997 6.178C4.98845 6.07926 4.96041 5.9832 4.91452 5.89536C4.86862 5.80752 4.80576 5.72965 4.72959 5.66625C4.65341 5.60284 4.56542 5.55517 4.47071 5.52598C4.376 5.49678 4.27644 5.48665 4.17779 5.49617C4.07914 5.50568 3.98336 5.53465 3.89597 5.58141C3.80858 5.62817 3.73133 5.69178 3.66868 5.76857C3.60602 5.84536 3.55921 5.93381 3.53095 6.0288C3.50268 6.12379 3.49352 6.22345 3.504 6.322L4.916 20.92C4.95823 21.3527 5.16001 21.7542 5.48203 22.0462C5.80405 22.3383 6.22325 22.5001 6.658 22.5H17.342C17.7769 22.5 18.1962 22.3382 18.5183 22.0459C18.8403 21.7536 19.042 21.3519 19.084 20.919L20.497 6.322C20.5161 6.12388 20.4557 5.9263 20.3291 5.7727C20.2025 5.61911 20.0201 5.5221 19.822 5.503C19.6239 5.4839 19.4263 5.54429 19.2727 5.67088C19.1191 5.79747 19.0221 5.97988 19.003 6.178L17.591 20.774C17.585 20.8359 17.5562 20.8933 17.5102 20.9351C17.4641 20.9769 17.4042 21 17.342 21H6.658C6.59582 21 6.53587 20.9769 6.48983 20.9351C6.44379 20.8933 6.41497 20.8359 6.409 20.774L4.997 6.178Z"
      fill="black"
    />
    <path
      d="M9.20584 7.50081C9.30421 7.495 9.40276 7.50862 9.49586 7.54091C9.58896 7.5732 9.67479 7.62352 9.74843 7.68899C9.82208 7.75446 9.8821 7.8338 9.92507 7.92248C9.96803 8.01116 9.9931 8.10744 9.99884 8.20582L10.4988 16.7058C10.5105 16.9046 10.4427 17.0999 10.3104 17.2487C10.1781 17.3975 9.99212 17.4876 9.79334 17.4993C9.59456 17.511 9.39929 17.4432 9.25048 17.3109C9.10167 17.1786 9.01151 16.9926 8.99984 16.7938L8.49984 8.29381C8.49403 8.19545 8.50765 8.0969 8.53994 8.0038C8.57223 7.91069 8.62254 7.82487 8.68802 7.75122C8.75349 7.67758 8.83283 7.61756 8.92151 7.57459C9.01019 7.53163 9.10647 7.50656 9.20484 7.50081H9.20584ZM15.4988 8.29381C15.5105 8.09503 15.4427 7.89976 15.3104 7.75095C15.1781 7.60214 14.9921 7.51198 14.7933 7.50032C14.5946 7.48865 14.3993 7.55642 14.2505 7.68873C14.1017 7.82103 14.0115 8.00704 13.9998 8.20582L13.4998 16.7058C13.4882 16.9045 13.5559 17.0996 13.6881 17.2483C13.8203 17.397 14.0062 17.4871 14.2048 17.4988C14.4035 17.5105 14.5986 17.4428 14.7474 17.3106C14.8961 17.1783 14.9862 16.9925 14.9978 16.7938L15.4988 8.29381Z"
      fill="black"
    />
  </svg>
);

export const userIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <path
      d="M4.36377 23V21.75C4.36377 19.4294 5.31198 17.2038 6.99981 15.5628C8.68764 13.9219 10.9768 13 13.3638 13M13.3638 13C15.7507 13 18.0399 13.9219 19.7277 15.5628C21.4156 17.2038 22.3638 19.4294 22.3638 21.75V23M13.3638 13C14.7277 13 16.0358 12.4732 17.0003 11.5355C17.9648 10.5979 18.5066 9.32608 18.5066 8C18.5066 6.67392 17.9648 5.40215 17.0003 4.46447C16.0358 3.52678 14.7277 3 13.3638 3C11.9998 3 10.6917 3.52678 9.72722 4.46447C8.76275 5.40215 8.22091 6.67392 8.22091 8C8.22091 9.32608 8.76275 10.5979 9.72722 11.5355C10.6917 12.4732 11.9998 13 13.3638 13Z"
      stroke="#1B1B1B"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export const worldIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="26"
    viewBox="0 0 27 26"
    fill="none"
  >
    <path
      d="M2.53027 13C2.53027 18.9833 7.38036 23.8334 13.3636 23.8334C19.3469 23.8334 24.1969 18.9833 24.1969 13C24.1969 7.01677 19.3469 2.16669 13.3636 2.16669C7.38036 2.16669 2.53027 7.01677 2.53027 13Z"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M14.4471 2.22083C14.4471 2.22083 17.6971 6.49999 17.6971 13C17.6971 19.5 14.4471 23.7792 14.4471 23.7792M12.2804 23.7792C12.2804 23.7792 9.03039 19.5 9.03039 13C9.03039 6.49999 12.2804 2.22083 12.2804 2.22083M3.21289 16.7917H23.5146M3.21289 9.20832H23.5146"
      stroke="black"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);
export const DeleteIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
    <path d="M21.3332 2.33333V4H28.3332C28.5984 4 28.8527 4.10536 29.0403 4.29289C29.2278 4.48043 29.3332 4.73478 29.3332 5C29.3332 5.26522 29.2278 5.51957 29.0403 5.70711C28.8527 5.89464 28.5984 6 28.3332 6H3.6665C3.40129 6 3.14693 5.89464 2.9594 5.70711C2.77186 5.51957 2.6665 5.26522 2.6665 5C2.6665 4.73478 2.77186 4.48043 2.9594 4.29289C3.14693 4.10536 3.40129 4 3.6665 4H10.6665V2.33333C10.6665 1.04533 11.7118 0 12.9998 0H18.9998C20.2878 0 21.3332 1.04533 21.3332 2.33333ZM12.6665 2.33333V4H19.3332V2.33333C19.3332 2.24493 19.2981 2.16014 19.2355 2.09763C19.173 2.03512 19.0882 2 18.9998 2H12.9998C12.9114 2 12.8266 2.03512 12.7641 2.09763C12.7016 2.16014 12.6665 2.24493 12.6665 2.33333ZM6.6625 8.23733C6.6511 8.10568 6.61372 7.97759 6.55252 7.86048C6.49132 7.74336 6.40752 7.63953 6.30595 7.55499C6.20438 7.47046 6.08707 7.40689 5.96079 7.36797C5.83451 7.32905 5.70176 7.31554 5.57023 7.32822C5.43869 7.34091 5.31098 7.37954 5.19446 7.44188C5.07795 7.50422 4.97495 7.58904 4.89141 7.69143C4.80787 7.79382 4.74545 7.91175 4.70776 8.0384C4.67008 8.16506 4.65787 8.29793 4.67184 8.42933L6.5545 27.8933C6.61081 28.4703 6.87985 29.0056 7.30921 29.395C7.73857 29.7844 8.29751 30.0001 8.87717 30H23.1225C23.7024 30.0001 24.2615 29.7842 24.6909 29.3945C25.1203 29.0048 25.3892 28.4692 25.4452 27.892L27.3292 8.42933C27.3546 8.16518 27.2741 7.90173 27.1053 7.69694C26.9365 7.49215 26.6933 7.36279 26.4292 7.33733C26.165 7.31187 25.9016 7.39239 25.6968 7.56117C25.492 7.72996 25.3626 7.97318 25.3372 8.23733L23.4545 27.6987C23.4465 27.7812 23.4081 27.8578 23.3467 27.9135C23.2854 27.9692 23.2054 28.0001 23.1225 28H8.87717C8.79427 28.0001 8.71432 27.9692 8.65294 27.9135C8.59155 27.8578 8.55313 27.7812 8.54517 27.6987L6.6625 8.23733Z" fill="black" />
    <path d="M12.2746 10.0014C12.4058 9.99366 12.5372 10.0118 12.6613 10.0549C12.7854 10.0979 12.8999 10.165 12.9981 10.2523C13.0963 10.3396 13.1763 10.4454 13.2336 10.5636C13.2909 10.6819 13.3243 10.8102 13.332 10.9414L13.9986 22.2747C14.0142 22.5398 13.9238 22.8001 13.7474 22.9986C13.571 23.197 13.323 23.3172 13.058 23.3327C12.7929 23.3483 12.5325 23.2579 12.3341 23.0815C12.1357 22.9051 12.0155 22.6571 12 22.3921L11.3333 11.0587C11.3255 10.9276 11.3437 10.7962 11.3867 10.6721C11.4298 10.5479 11.4969 10.4335 11.5842 10.3353C11.6715 10.2371 11.7773 10.1571 11.8955 10.0998C12.0138 10.0425 12.1421 10.0091 12.2733 10.0014H12.2746ZM20.6653 11.0587C20.6808 10.7937 20.5905 10.5333 20.4141 10.3349C20.2377 10.1365 19.9897 10.0163 19.7246 10.0007C19.4596 9.98519 19.1992 10.0756 19.0008 10.252C18.8024 10.4284 18.6822 10.6764 18.6666 10.9414L18 22.2747C17.9844 22.5396 18.0747 22.7998 18.251 22.9981C18.4273 23.1964 18.6751 23.3165 18.94 23.3321C19.2048 23.3476 19.465 23.2573 19.6633 23.0811C19.8616 22.9048 19.9817 22.6569 19.9973 22.3921L20.6653 11.0587Z" fill="black" />
  </svg>
);

export const googleIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="28"
    height="28"
    viewBox="0 0 28 28"
    fill="none"
  >
    <path
      d="M25.4399 11.715H24.5002V11.6666H14.0002V16.3333H20.5936C19.6317 19.0498 17.0469 20.9999 14.0002 20.9999C10.1344 20.9999 7.00016 17.8657 7.00016 13.9999C7.00016 10.1342 10.1344 6.99992 14.0002 6.99992C15.7846 6.99992 17.408 7.67309 18.6441 8.77267L21.944 5.47275C19.8603 3.53084 17.0732 2.33325 14.0002 2.33325C7.55725 2.33325 2.3335 7.557 2.3335 13.9999C2.3335 20.4428 7.55725 25.6666 14.0002 25.6666C20.4431 25.6666 25.6668 20.4428 25.6668 13.9999C25.6668 13.2177 25.5863 12.4541 25.4399 11.715Z"
      fill="#FFC107"
    />
    <path
      d="M3.67871 8.56967L7.51179 11.3808C8.54896 8.81292 11.0608 6.99992 14.0002 6.99992C15.7846 6.99992 17.408 7.67309 18.6441 8.77267L21.944 5.47275C19.8604 3.53084 17.0732 2.33325 14.0002 2.33325C9.51904 2.33325 5.63288 4.86317 3.67871 8.56967Z"
      fill="#FF3D00"
    />
    <path
      d="M14 25.6666C17.0135 25.6666 19.7517 24.5133 21.8219 22.6379L18.2111 19.5824C17.0004 20.5031 15.521 21.0011 14 20.9999C10.9655 20.9999 8.38894 19.065 7.41827 16.3647L3.61377 19.296C5.5446 23.0742 9.46577 25.6666 14 25.6666Z"
      fill="#4CAF50"
    />
    <path
      d="M25.4398 11.7152H24.5V11.6667H14V16.3334H20.5934C20.1333 17.6263 19.3045 18.7561 18.2093 19.5832L18.2111 19.582L21.8219 22.6375C21.5664 22.8697 25.6667 19.8334 25.6667 14.0001C25.6667 13.2178 25.5862 12.4542 25.4398 11.7152Z"
      fill="#1976D2"
    />
  </svg>
);

export const facebookIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="24"
    viewBox="0 0 14 24"
    fill="none"
  >
    <path
      d="M8.81325 13.7499H11.8634L13.0834 9.08325H8.81325V6.74992C8.81325 5.54825 8.81325 4.41659 11.2533 4.41659H13.0834V0.496585C12.6857 0.446419 11.1838 0.333252 9.59774 0.333252C6.28532 0.333252 3.93308 2.26642 3.93308 5.81659V9.08325H0.272949V13.7499H3.93308V23.6666H8.81325V13.7499Z"
      fill="#3B5998"
    />
  </svg>
);

/* order */
export const rafalIcon = (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
  >
    <path
      d="M9.42899 18.8578L16.2861 12.0007L9.42899 5.14355"
      stroke="url(#paint0_linear_467_16999)"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <defs>
      <linearGradient
        id="paint0_linear_467_16999"
        x1="12.9338"
        y1="5.14355"
        x2="12.7367"
        y2="18.855"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#F5841F" />
        <stop offset="1" stopColor="#ED1C24" />
      </linearGradient>
    </defs>
  </svg>
);

export const rafalArrowIcon =(
  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
  <path d="M14.5713 5.14216L7.71411 11.9993L14.5713 18.8564" stroke="url(#paint0_linear_3266_17303)" strokeWidth="2" stroke-linecap="round" stroke-linejoin="round"/>
  <defs>
    <linearGradient id="paint0_linear_3266_17303" x1="11.0665" y1="18.8564" x2="11.2636" y2="5.14499" gradientUnits="userSpaceOnUse">
      <stop stopColor="#F5841F"/>
      <stop offset="1" stopColor="#ED1C24"/>
    </linearGradient>
  </defs>
</svg>
)
export const editIcon = (
  <svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" viewBox="0 0 32 32" fill="none">
  <path d="M27.9998 16.0002C27.6462 16.0002 27.3071 16.1407 27.057 16.3908C26.807 16.6408 26.6665 16.98 26.6665 17.3336V25.3336C26.6665 25.6872 26.526 26.0263 26.276 26.2764C26.0259 26.5264 25.6868 26.6669 25.3332 26.6669H6.6665C6.31288 26.6669 5.97374 26.5264 5.7237 26.2764C5.47365 26.0263 5.33317 25.6872 5.33317 25.3336V6.66691C5.33317 6.31329 5.47365 5.97415 5.7237 5.7241C5.97374 5.47405 6.31288 5.33358 6.6665 5.33358H14.6665C15.0201 5.33358 15.3593 5.1931 15.6093 4.94305C15.8594 4.693 15.9998 4.35387 15.9998 4.00024C15.9998 3.64662 15.8594 3.30748 15.6093 3.05744C15.3593 2.80739 15.0201 2.66691 14.6665 2.66691H6.6665C5.60564 2.66691 4.58822 3.08834 3.83808 3.83848C3.08793 4.58863 2.6665 5.60605 2.6665 6.66691V25.3336C2.6665 26.3944 3.08793 27.4119 3.83808 28.162C4.58822 28.9122 5.60564 29.3336 6.6665 29.3336H25.3332C26.394 29.3336 27.4115 28.9122 28.1616 28.162C28.9117 27.4119 29.3332 26.3944 29.3332 25.3336V17.3336C29.3332 16.98 29.1927 16.6408 28.9426 16.3908C28.6926 16.1407 28.3535 16.0002 27.9998 16.0002ZM7.99984 17.0136V22.6669C7.99984 23.0205 8.14031 23.3597 8.39036 23.6097C8.64041 23.8598 8.97955 24.0002 9.33317 24.0002H14.9865C15.162 24.0013 15.3359 23.9676 15.4984 23.9013C15.6608 23.8349 15.8086 23.7372 15.9332 23.6136L25.1598 14.3736L28.9465 10.6669C29.0715 10.543 29.1707 10.3955 29.2384 10.233C29.3061 10.0705 29.3409 9.89626 29.3409 9.72024C29.3409 9.54423 29.3061 9.36995 29.2384 9.20748C29.1707 9.045 29.0715 8.89753 28.9465 8.77358L23.2932 3.05358C23.1692 2.92861 23.0218 2.82941 22.8593 2.76172C22.6968 2.69403 22.5225 2.65918 22.3465 2.65918C22.1705 2.65918 21.9962 2.69403 21.8337 2.76172C21.6713 2.82941 21.5238 2.92861 21.3998 3.05358L17.6398 6.82691L8.3865 16.0669C8.26293 16.1915 8.16516 16.3393 8.09881 16.5017C8.03246 16.6642 7.99882 16.8381 7.99984 17.0136ZM22.3465 5.88024L26.1198 9.65358L24.2265 11.5469L20.4532 7.77358L22.3465 5.88024ZM10.6665 17.5602L18.5732 9.65358L22.3465 13.4269L14.4398 21.3336H10.6665V17.5602Z" fill="#1B1B1B"/>
</svg>
)