import React, { useEffect } from "react";
import { Box, Grid } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { GetCatego } from "../../store/products/categorySlice";
import { useNavigate } from "react-router-dom";
import { setBread } from "../../store/products/productsSlice";

export default function CategoryTwo() {
  const category = useSelector((state) => state.categorySection.category);
  console.log("categordddy", category);
  const Navigate = useNavigate(); // Use the useNavigate hook

  const cat = category?.section2;

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(GetCatego());
  }, []);

  return (
    <Box sx={{ marginBlock: "50px" }}>
      {cat && (
        <Box
          sx={{
            marginTop: "50px",
            paddingTop: "20px",
            textAlign: "center",
            width: "100%",
            cursor: "pointer",
          }}
          onClick={() => {
            Navigate(`/category/${cat.id}`, {
              state: { category_id: cat.id },
            });
            dispatch(setBread(cat.name));
            dispatch({
              type: "products/setCategoryID",
              payload: [cat.id],
            });
          }}
        >
          <img
            className="radiusimg"
            width={"100%"}
            height={"400px"}
            src={cat.cat_image}
            alt=""
          />
        </Box>
      )}
    </Box>
  );
}
