import { configureStore } from "@reduxjs/toolkit";
import counterReducer from "./counterSlice";
import globalSlice from "./globalSlice";
import userSlice from "./user/user";
import productsSlice from "./products/productsSlice";
import productSlice from "./product/productSlice";
import recommendedSlice from "./recommended/recommendedSlice";
import reviewSlice from "./review/reviewSlice";
import wishlistSlice from "./wishlist/wishlistSlice";
import cartSlice from "./cart/cartSlice";
import colorSlice from "./color/colorSlice";
import videoSlice from "./video/videoSlice";
import offerSlice from "./offer/offerSlice";
import guestCart from "./guestCart/guestCart";
import checkoutSlice from "./checkout/checkoutSlice";
import orderSlice from "./order/orderSlice";
import comparisonSlice from "./comparison/comparisonSlice";
import adsSlice from "./ads/adsSlice";
import profileSlice from "./profile/profileSlice";
import paymentSlice from "./payment/paymentSlice";
import categorySlice from "./products/categorySlice";
export const store = configureStore({
  reducer: {
    counter: counterReducer,
    global: globalSlice,
    categorySection:categorySlice ,
    user: userSlice,
    products: productsSlice,
    product: productSlice,
    recommended: recommendedSlice,
    review: reviewSlice,
    wishlists: wishlistSlice,
    cart: cartSlice,
    guestCart: guestCart,
    color: colorSlice,
    video: videoSlice,
    offer: offerSlice,
    checkout: checkoutSlice,
    orders: orderSlice,
    compare: comparisonSlice,
    ads: adsSlice,
    profile: profileSlice,
    payment: paymentSlice,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
