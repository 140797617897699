import * as React from "react";
import Button from "@mui/material/Button";
import Dialog from "@mui/material/Dialog";
import ListItemText from "@mui/material/ListItemText";
import ListItem from "@mui/material/ListItem";
import List from "@mui/material/List";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import IconButton from "@mui/material/IconButton";
import Typography from "@mui/material/Typography";
import CloseIcon from "@mui/icons-material/Close";
import Slide from "@mui/material/Slide";
import { TransitionProps } from "@mui/material/transitions";
import { Box } from "@mui/material";
import InputField from "../common/InputField";
import SearchInput from "../common/searchInput";


const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

export default function DialogSearch({ open, onClose }: any) {
  //   const [open, setOpen] = React.useState(false);

  return (
    <div>
      <Dialog
        fullScreen
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        >
        <Box sx={{textAlign:"right", padding:"10px", fontWeight:"500", fontSize:"21px"}} onClick={() => onClose()}>x</Box>
          {/* search input */}
          <Box sx={{flex:"1", padding:"10px"}}>
            <SearchInput />
          </Box>
      </Dialog>
    </div>
  );
}
