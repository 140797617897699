import {Box, Skeleton} from "@mui/material";
import React, {useRef, useState} from "react";
import {H2} from "../../style/style";
import {t} from "i18next";
import {useSelector} from "react-redux";
import {RootState} from "../../store/store";
import {OffersProps} from "../../types/offer";
import Slider from "react-slick";
import ProductCard from "../common/productCard";
import productImage from "../../assets/recommend/product-6.png";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";

const OfferSection = () => {
    const {loading} = useSelector((state: RootState) => state.offer);
    const offers = useSelector((state: RootState) => state.offer.offer);
    // Add state variable to track the current slide index
    const [currentSlideIndex, setCurrentSlideIndex] = useState(0);
    const arrowRef = useRef<Slider>(null);


    const handlePrevClick = (): void => {
        if (arrowRef.current !== null) {
            arrowRef.current.slickPrev();
            setCurrentSlideIndex(currentSlideIndex - 1);
        }
    };

    const handleNextClick = (): void => {
        if (arrowRef.current !== null) {
            arrowRef.current.slickNext();
            setCurrentSlideIndex(currentSlideIndex + 1);
        }
    };


    var settings = {
        className: "serv",
        speed: 2000,
        slidesToShow: offers?.results?.length >= 4 ? 4 : offers?.results?.length,
        slidesToScroll: 1, // initialSlide: 0,
        autoplay: true,
        infinite: true,
        pauseOnHover: true,
        responsive: [
            {
                breakpoint: 1400, settings: {
                    slidesToShow: 3, slidesToScroll: 1, infinite: true,
                },
            }, {
                breakpoint: 1200, settings: {
                    slidesToShow: 3, slidesToScroll: 1, infinite: true,
                },
            }, {
                breakpoint: 1024, settings: {
                    slidesToShow: 2, slidesToScroll: 1, infinite: true,
                },
            }, {
                breakpoint: 750, settings: {
                    slidesToShow: 1, slidesToScroll: 1, initialSlide: 1,
                },
            }, {
                breakpoint: 480, settings: {
                    slidesToShow: 1, slidesToScroll: 1,
                },
            },],
    };
    return (
        <Box mt="48px">
            <H2 variant="h2" mb="24px">
                {t("Top Deals")}
            </H2>

            <Box mt="24px">
                <Box sx={{
                    position: "relative",
                }}>
                    {loading ? (<Slider {...settings} ref={arrowRef}>

                        {Array.from(new Array(4)).map((item) => {
                            return (<Box>
                                <Skeleton
                                    variant="rectangular"
                                    width="100%"
                                    height={350}
                                    sx={{
                                        textAlign: "center", display: "block", margin: "auto",
                                    }}
                                />
                            </Box>);
                        })}
                    </Slider>) : (<Slider {...settings} ref={arrowRef}>
                            {offers?.results?.map((offer: OffersProps, index: number) => {
                                return (
                                    <Box key={index}>
                                        <ProductCard type="" image={productImage} product={offer}/>
                                    </Box>
                                );
                            })}
                        </Slider>
                    )}
                    <div className="slider-arrow">
                        {currentSlideIndex > 0 && (
                            <button onClick={handlePrevClick} className="back">
                                <NavigateBeforeIcon/>
                            </button>
                        )}
                        <button onClick={() => handleNextClick()} className="next">
                            <NavigateNextIcon/>
                        </button>
                    </div>
                </Box>
            </Box>
        </Box>
    );
};

export default OfferSection;
