import React, { useEffect } from "react";
import { Box, Typography, Grid } from "@mui/material";
import HeroHeading from "../../components/common/HeroHeading";
import ProfileLayout from "../../components/profile/profileLayout";
import orderImage from "../../assets/order-1.png";
import { colors } from "../../utils/theme";
import i18next, { t } from "i18next";
import { rafalArrowIcon, rafalIcon } from "../../assets/svg/svg";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../store/store";
import { RequestGetOrders } from "../../store/order/orderSlice";
import { Link, useNavigate } from "react-router-dom";
import BreadCrumbs from "../../components/common/breadCrumbs";

const Order = () => {
  const orders = useSelector((state: RootState) => state.orders.orders);
  const dispatch = useDispatch<AppDispatch>();
  const navigate=useNavigate()

  useEffect(() => {
    dispatch(RequestGetOrders());
  }, []);
  const crumbs = [
    { label:` ${t("Home")}`, link: "/", active: false },
    { label:`${t('order')}`, link: `/profile/order`, active: true },
  ];
  return (
    <ProfileLayout crumbs={crumbs}>
      <HeroHeading heading={t("orders")} />
      
      <Grid container spacing={3}>
        <Grid item xs={12} sm={5}>
          {orders?.map((order: any, index: number) => {
            return (
              <>
                <Box mb={"25px"} sx={{width:"100%"}} key={index} onClick={()=>navigate(`/profile/order/${order?.id}`)}>
                  <Box sx={{ display: "flex", gap: "25px" }}>
                    <img src={orderImage} alt="test" />
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "space-between",
                        alignItems: "center",
                        flex: "1",
                      }}
                    >
                      <Box>
                        <Typography
                          sx={{
                            fontSize: "18px",
                            fontWeight: "500",
                            mb: "8px",
                          }}
                        >
                          {t('order')} #{order?.id}
                        </Typography>
                        <Typography
                          sx={{
                            fontSize: "15px",
                            fontWeight: "600",
                            color: "#7F7F7F",
                          }}
                        >
                          {order?.total_amount} {t('egp')} ({" "}
                          {order?.items?.length} {t('item')} )
                        </Typography>
                      </Box>
                      <Link to={`/profile/order/${order?.id}`}
                        style={{
                          background: "transparent",
                          border: "none",
                          cursor: "pointer",
                          
                        }}
                      >
                        {i18next.language==="en"? rafalIcon:rafalArrowIcon}
                      </Link>
                    </Box>
                  </Box>
                </Box>
              </>
            );
          }).reverse()}
          <Typography mt="32px" sx={{ fontSize: "14px", fontWeight: "400" }}>
            {orders?.length} {t('results')}
          </Typography>
        </Grid>
      </Grid>
    </ProfileLayout>
  );
};

export default Order;
